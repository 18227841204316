import Content from "./Content/Content";
import CenterContent from "./Content/CenterContent";
import Executors, {Executor} from "./Services/Executors";
import React, {useEffect} from "react";
import ServicesList, {Service} from "./Services/ServicesList";
import ServicePage from "./Services/ServicePage";




export interface IServiceProps {
    setSelectedExecutor: any
    setService: any
    findService: any
    setFindService: any
    service?: Service
    executor?: Executor
}



// eslint-disable-next-line no-unused-vars
export default function Services(props: IServiceProps) {

    const selectedExecutor = props.executor
    const setSelectedExecutor = props.setSelectedExecutor
    const setService = props.setService
    const findService = props.findService
    const setFindService = props.setFindService
    const service = props.service

    const changeExecutor = (executor: Executor) => {
        setSelectedExecutor(executor)
    }

    useEffect(() => {
        console.log("Services: "+selectedExecutor)
    }, [selectedExecutor]);



    return (
        <>
            <Content>
                {selectedExecutor === undefined && service === undefined &&
                <CenterContent isFull={true} opacity={0.7}>
                    <Executors setSelectedExecutor={setSelectedExecutor} setService={setService} findService={findService} setFindService={setFindService}/>
                </CenterContent>
                }

                {selectedExecutor !== undefined && service === undefined &&
                <CenterContent isFull={true} opacity={0.7}>
                    <ServicesList setSelectedExecutor={changeExecutor} setService={setService} executor={selectedExecutor} findService={findService} setFindService={setFindService}/>
                </CenterContent>
                }

                {service !== undefined && selectedExecutor !== undefined &&
                <CenterContent isFull={true} opacity={0.7}>
                    <ServicePage setService={setService} service={service} setSelectedExecutor={setSelectedExecutor} findService={findService} setFindService={setFindService} executor={selectedExecutor}/>
                </CenterContent>
                }



            </Content>
        </>
    );
}