export default class Message extends Map{
    constructor() {
        super();
    }

    public get(key: string): any | undefined {
        return super.get(key);
    }

    public set(key: string, value: any): this {
        return super.set(key, value);
    }

    public toString(): string {
        return JSON.stringify(this.toJSONObject());
    }

    public toJSONObject(): any{
        const object: any = {};
        this.forEach((value, key) => {
            object[key] = value;
        });
        return object;
    }

    public static canParse(string: string): boolean{
        try{
            JSON.parse(string);
            return true;
        }catch (e) {
            return false;
        }

    }
    public static fromString(string: string): Message{
        const message = new Message();
        const json = JSON.parse(string);
        for(const key in json){
         //   console.log("set "+key+" to "+json[key]+" in "+string+"")
            message.set(key, json[key]);
        }
        return message;
    }
}