
import CenterContent from "./Content/CenterContent";
import RightContent from "./Content/RightContent";


import React, {useEffect, useRef} from "react";
import Popup from "../../Utils/Popup";
import {RequestBuilder} from "../../../RestCommunication/RequestBuilder";
import {toast} from "react-toastify";
import Content from "./Content/Content";
import SessionManager from "../../../WebSocket/SessionManager";
import {FaTrash} from "react-icons/fa6";




interface IProjectsProps {
    ips: string[];
    uuid: string;
    projectId: string;
}

// eslint-disable-next-line no-unused-vars
const listIps = (ips: string[], uuid: string, projectID : string) => {
    return ips.map((ip: string) =>
        oneDiv(ip,uuid,projectID)
    )
}
const oneDiv = (ip: string, uuid: string, projectID: string) => {
    const trueIp = ip
    ip = ip.substring(0, ip.length - 8) + "*********"
    return (
        <div className={""}>
            <div className={"flex gap-8 flex-row bg-neutral-100 rounded-xl  w-5/12  m-4 ml-16 "}>

                <div className={"h-full lg:py-12 sm:py-2 bg-neutral-100 rounded-xl"} style={{
                    background: "linear-gradient(180deg, rgba(195,172,255,1) 0%, rgba(255,171,229,1) 62%, rgba(242,194,194,1) 96%)"
                }
                }>
                    <object data="/svg/server.svg" type="image/svg+xml" className={"lg:mx-20 sm:mx-5 fill-white"} width="100" height="100">
                    </object>
                </div>
                <div className={"flex flex-col"}>
                    <p className={"text-2xl  font-mono font-light mr-1 bg-purple-100 rounded-lg p-5 pt-5  ml-auto"}>{ip}</p>
                    <div className={"flex flex-row "}>
                        <button className={"bg-red-200 hover:bg-red-400 font-light  rounded-lg p-5 ml-auto  mt-10"} onClick={
                            () => {
                                sendDeleteRequest(trueIp,uuid,projectID)
                            }
                        }><FaTrash className={"text-2xl"}/>
                        </button>

                    </div>
                </div>


            </div>
        </div>

    )
}


const sendDeleteRequest = (ip: string, uuid: string, projectID: string) => {

        const request = new RequestBuilder("POST", "/v1/hypervisor/unregisternode",true).setBody({
            uuid: uuid,
            ip: ip
        });
        const id = toast.loading("Loading...")
        request.sendRequest().then((response) => {
            if (response == null || response.getResponse() == null) {
                toast.update(id, {render: 'Error', type: 'error', isLoading: false, autoClose: 5000});
                return;
            }

            response.onError(error => {
                toast.update(id, {render: error, type: 'error', isLoading: false, autoClose: 5000});
                return;
            })

            response.onUnauthorized(() => {
                toast.update(id, {render: 'Unauthorized', type: 'error', isLoading: false, autoClose: 5000});
                return;
            });

            if (response.getStatus() === 200) {
                toast.update(id, {render: 'Success', type: 'success', isLoading: false, autoClose: 5000});
                const hypervisorData = JSON.parse(localStorage.getItem("hypervisorData-"+projectID) as string)
                const ips = hypervisorData.nodes
                const index = ips.indexOf(ip)
                ips.splice(index, 1);

                localStorage.setItem("hypervisorData-"+projectID, JSON.stringify(hypervisorData))
                window.location.reload();
            }
        })

}
const sendRequest = (ip: string, uuid: string,projectID: string) => {

    const request = new RequestBuilder("POST", "/v1/hypervisor/registernode",true).setBody({
        uuid: uuid,
        ip: ip
    });
    const id = toast.loading("Loading...")
    request.sendRequest().then((response) => {
        if(response == null || response.getResponse() == null) {
            toast.update(id, { render: 'Error', type: 'error', isLoading: false, autoClose: 5000 });
            return;
        }



        response.onError(error => {
            toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 5000 });
            return;
        })

        response.onUnauthorized(() => {
            toast.update(id, { render: 'Unauthorized', type: 'error', isLoading: false, autoClose: 5000 });
            return;
        });

        console.log("Responsee => "+response.getResponse())
        console.log("Responsee => "+JSON.stringify(response.getResponse()))
        console.log("Responsee => "+response.getStatus())
        console.log("Responsee => "+response.getResponse()?.status)
        if(response.getStatus()=== 200) {
            toast.update(id, { render: 'Success', type: 'success', isLoading: false, autoClose: 5000 });
            const hypervisorData = JSON.parse(localStorage.getItem("hypervisorData-"+projectID) as string)
            const ips = hypervisorData.nodes
            ips.push(ip)
            localStorage.setItem("hypervisorData-"+projectID, JSON.stringify(hypervisorData))
            window.location.reload();
        }

        return response.getResponse();
    })
}
export default function Nodes(props: IProjectsProps) {


    useEffect(() => {
        SessionManager.getSessionListener().exitCurrentFrame();
    }, []);

    const popupRef = useRef<any>(null);
    const tutorialRef = useRef<any>(null);
    const tutorial  = Popup({children:
       <>
            <div className={"flex flex-col"}>
                <p className={"text-lg font-light"}>Install the DNLauncher.jar on your node</p>
                <button className={"bg-purple-500 hover:bg-purple-400 rounded-lg px-4 py-2 mt-4 text-white mx-auto justify-center w-full"} onClick={
                    () => {
                        window.location.href = "https://cdn.dreamnetwork.cloud/hypervisor/launcher/DNLauncher.jar"
                    }
                }>Download</button>
                <p className={"text-lg font-light mt-4"}>Or download with wget</p>
                <p className={" font-light text-sm font-mono mt-4 border-2 border-gray-300 rounded-xl p-2 ml-2"}>wget https://cdn.dreamnetwork.cloud/hypervisor/launcher/DNLauncher.jar</p>
                <p className={"text-lg font-light mt-4"}>Run the DNLauncher.jar with the following command</p>
                <p className={"text-sm font-mono font-light mt-4 border-2 border-gray-300 rounded-xl p-2 ml-2"}>java -jar DNLauncher.jar</p>
            </div>
        </>, name: "Tutorial", onClose: () => {}, ref: tutorialRef})


    const register = Popup({children:
            <>
                <div>
                    <p className={"text-lg font-light"}>Set the IP of your Node</p>
                    <label className={"ml-4 font-light"}>Ip</label>
                    <input id="ip" type="text" placeholder={"Ex: 127.0.0.1"}
                           className={"border-2 ml-3 border-gray-300 rounded-xl p-2 mt-4"}/>
                    <label className={"ml-4 font-light flex-row "}>Port</label>
                    <input id="port" type="number" placeholder={"Ex: 14520"} defaultValue={"14520"}
                           className={"border-2 border-gray-300 rounded-xl py-1.5 ml-3 px-1 mt-4 w-20"}/>
                    <br/>
                    <button
                        className={"bg-purple-500 hover:bg-purple-400 rounded-lg px-4 py-2 mt-4 text-white mx-auto justify-center w-full"}
                        onClick={() => {
                            const ip = (document.getElementById("ip") as HTMLInputElement).value
                            if (ip === "") {
                                alert("Your ip is empty")
                                return;
                            }
                            if (ip.length < 3) {
                                alert("Your ip is too short")
                                return;
                            }
                            const port = (document.getElementById("port") as HTMLInputElement).value
                            if (port === "") {
                                alert("Your port is empty")
                                return;
                            }
                            sendRequest(ip+":"+port, props.uuid,props.projectId)
                        }}>Register
                    </button>


                </div>
            </>
        , name: "Register a new node", onClose: () => {}, ref: popupRef})

    return (

        <>
            {register}
            {tutorial}

            <div className="max-sm:flex-col sm:flex-row flex">
        <Content>
            <div className={"flex-row flex"}>
                <CenterContent isFull={false}>

                    { <div className="flex flex-col  ">
                    <p className={"text-3xl mt-4 text-center"}>Nodes</p>
                    {props.ips.length === 0 && <p className={"text-lg mt-4 underline text-center"}>There is no node, please register a new node</p>}
                    <div className={"flex flex-row flex-wrap  ml-5"}>
                        {listIps(props.ips,props.uuid,props.projectId)}
                    </div>
                </div> }
                </CenterContent>
                <RightContent>
                    <div className="sm:flex sm:flex-col items-center justify-center">
                        <p className={"text-lg mt-4 max-sm:invisible max-sm:absolute"}>Nodes management</p>
                        <button
                            className={"bg-purple-500 hover:bg-purple-400 rounded-lg sm:px-4 sm:py-2 sm:mt-4 text-white max-sm:p-4 max-sm:w-full"}
                            onClick={() => {
                                popupRef.current?.show()
                            }}>Register a new node
                        </button>
                        <p className={"mt-8 max-sm:invisible max-sm:absolute"}>Needs more nodes ?</p>

                        <p className={"underline max-sm:invisible max-sm:absolute"}>Contact us</p>
                        <button
                            className={"bg-purple-500 hover:bg-purple-400 rounded-lg px-4 py-2 mt-4 text-white mx-auto justify-center w-full"}
                            onClick={() => {
                                tutorialRef.current?.show()
                            }}>How to run dream ?
                        </button>
                        {/*<button className={"bg-red-500 hover:bg-red-400 rounded-lg px-4 py-2 mt-4 text-white"}>Upgrade</button>*/}
                    </div>
                </RightContent>
            </div>

        </Content>
            </div>

        </>
    );
}