import {useEffect, useState} from "react";
import {WidthManager} from "../WidthManager";
import {WindowEventListener} from "../../../Utils/WindowEventListener";


interface CenterContentProps {
    children: any
    isFull?: boolean
    opacity?: number
}

export default function CenterContent({children, isFull = false, opacity}: CenterContentProps) {
    const [availableWidth,setWidth] = useState(WidthManager.getAvailableWidth(isFull)-(window.innerWidth < 640 ? 0 : 50));
    useEffect(() => {
        WindowEventListener.registerEventListener("centerResize","resize", () => {
            WidthManager.refreshWidth()
            const minus = window.innerWidth < 640 ? 0 : 50
            setWidth(WidthManager.getAvailableWidth(isFull)-minus)
        });
    });
    return (

        <div className="h-screen opacity-100 flex-row flex">
            {isFull && <div
                className=" h-[89%] absolute sm:ml-6 mt-[2vh]  bg-white dark:bg-black  rounded-xl drop-shadow-lg max-sm:w-screen sm:w-full"
                style={
                    {
                        borderRadius: "16px",
                        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                        backdropFilter: "blur(0px)",
                        WebkitBackdropFilter: "blur(5px)",
                        border: "1px solid rgba(255, 255, 255, 0.3)",
                        opacity: opacity,

                        minWidth: availableWidth + "px",
                        maxWidth: availableWidth + "px",
                        width: availableWidth + "px",

                    }
                }>

            </div>}
            {isFull &&

                <div className=" min-sm:absolute rounded-xl drop-shadow-lg overflow-y-auto mt-[3vh] sm:ml-6   " style={{
                    height: "89%",
                    minWidth: availableWidth + "px",
                    maxWidth: availableWidth + "px",
                    width: availableWidth + "px",
                }}>
                    <div className="">
                        {children}
                    </div>


                </div>}
            {!isFull && <div
                className="bg-white w-4/6 dark:bg-black max-sm:w-full h-5/6 sm:ml-2 xl:ml-6 rounded-xl drop-shadow-lg overflow-y-auto md:mt-5 max-sm:mt-2" style={{
                height: "88%",
                minWidth: availableWidth + "px",
                maxWidth: availableWidth + "px",
                width: availableWidth + "px",
            }}>
                <div className="opacity-1">
                    {children}
                </div>
            </div>}
        </div>
    );
}