import React, {useState} from 'react';

import "./Auth.css"
import {TERipple} from "tw-elements-react";
import "tw-elements-react/dist/css/tw-elements-react.min.css";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AuthState, ChildAuthProps} from "./ContentAuthPage";
import {animated, useSpring} from "@react-spring/web";
import {getUser} from "./LoginRequest";
import {useNavigate} from "react-router-dom";


//import axios from "axios";




// eslint-disable-next-line no-unused-vars
const LoginPage: React.FunctionComponent<ChildAuthProps> = (props) => {
    const [lock, setLock] = useState(false)
    const [buttonClass, setButtonClass] = useState("px-24 py-2 bg-purple-400 focus:bg-purple-500 hover:bg-purple-600 rounded-md")
    const navigate = useNavigate();
    const [effect] = useSpring(
        () => ({
            from: { y: -50, opacity: 0 },
            to: { y: 0,opacity: 1 },
            config: {
                mass:1.5,
                tension: 120,
                friction: 14,
                precision: 0.01,
                velocity: 0,
            },
        }),
        []
    )

    return (
        <>
            <ToastContainer/>
            <div id="background" className="">
                <div id="sub-background" className="flex flex-col items-center justify-center min-h-screen py-2">
                <div className={"w-full h-full flex flex-col items-center justify-center"}>
                <div id={"block"}
                    className="flex flex-col items-center justify-center w-full px-6 bottom-3 bg-white shadow-md max-w-md sm:rounded-lg">
                    <div className={"my-4"}>

                        <img src="/new_cloud_black_mini.png" alt="logo" className="w-16"/>
                    </div>

                    <h1 className="text-2xl font-bold text-gray-800">Sign in</h1>
                    <animated.div style={effect}>
                    <div className="mt-4">
                        <label className="block mb-2 text-sm font-bold text-gray-700">Email</label>
                        <input onKeyDown={event => {
                            if (event.key === "Enter") {
                                onEnter();
                            }
                        }} id="email" type="text"
                               className="w-full px-6 py-2 placeholder-gray-300 border border-gray-300 focus:bg-fuchsia-50  focus:border-purple-500 rounded-md focus:outline-none"
                               placeholder="Email"/>
                        <div className="flex flex-row mt-2 text-xs text-gray-600">
                            <label className="block mb-2 text-sm font-bold text-gray-700">Password</label>
                            <a className={"flex justify-end flex-auto text-purple-400 cursor-pointer"} onClick={() => {
                                props.setState(AuthState.FORGOT_PASSWORD)
                            }}>Forgot Password?</a>
                        </div>
                        <input onKeyDown={event => {
                            if (event.key === "Enter") {
                                onEnter();
                            }
                        }} id={"password"} type="password"
                                 className="w-full px-6 py-2 placeholder-gray-300  border border-gray-300  focus:bg-fuchsia-50   focus:border-purple-500  focus:outline-none rounded-md"
                                 placeholder="Password"/>
                        <TERipple rippleColor="light" className={"flex justify-center w-full "}>
                            <button className={buttonClass + " mt-8 text-white focus:outline-none shadow-xl w-auto"}
                                    onClick={() => {
                                        onEnter();
                                    }
                                    }>Login</button>
                        </TERipple>
                        <div className={"mt-12"}>
                            <h2><span>Or use OAuth</span></h2>
                        </div>

                        <div className={"flex flex-row justify-center mt-4"}>
                            <a href="https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png" className={"pt-1 pb-1 pr-4 pl-4 border-2 rounded-xl transition-colors duration-500 hover:bg-blue-100 "}>
                                <img src="https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png" alt="google" className="w-6"/>
                            </a>
                            <a href="https://cdn-icons-png.flaticon.com/512/25/25231.png" className={"ml-10 pt-1 pb-1 pr-4 pl-4 border-2 rounded-xl transition-colors duration-500 hover:bg-blue-100 "}>
                                <img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" alt="github" className="w-6"/>
                            </a>
                        </div>
                        <div className="flex justify-center mt-2 text-xs text-gray-600 pb-4">
                            <a onClick={() => {
                                console.log("clicked")
                                if(lock) return;
                                props.setState(AuthState.REGISTER);
                            }} className={"text-base text-purple-900 underline transition-colors duration-500 hover:text-purple-600 hover:cursor-pointer"}>Don't have an account ?</a>
                        </div>
                    </div>
                    </animated.div>
                </div>
                </div>

                </div>
            </div>


        </>
    )

    function onEnter(){
        if(lock) return;
        setLock(true)
        const mail = document.getElementById("email") as HTMLInputElement;
        const password = document.getElementById("password") as HTMLInputElement;

        if(mail.value === "" || password.value === "") {
            toast.warning( "Please fill in all fields");
            console.log("Please fill in all fields");
            setLock(false)
            return;
        }
        if(props.token === null) {
            toast.warning( "The captcha is not valid please wait or reload the page");
            setLock(false)
            return;
        }
        getUser(mail.value,password.value,props.token).then((data) => {
            console.log(data);

            if(data === undefined) {
                props.reloadToken();
            }
            setButtonClass("rounded-md duration-500 transition-all px-24 py-2 bg-purple-400 focus:bg-purple-500 hover:bg-purple-600 rounded-md");
            setLock(false);
            if(data === undefined) {
                return;
            }

            if(data.data !== undefined) {
                navigate("/", { replace: true });
            }
        }).catch((error) => {
            console.log(error);
            setButtonClass("rounded-md duration-500 transition-all px-24 py-2 bg-purple-400 focus:bg-purple-500 hover:bg-purple-600 rounded-md");
            setLock(false);
        })

        setButtonClass("cursor-default duration-500 px-20 py-1.5 rounded-md transition-all bg-gray-400 focus:bg-gray-400 hover:bg-gray-400");
    }
}
export default LoginPage;

// <button onClick={() => {
//
//                             // get username
//                             const username = (document.getElementById("username") as HTMLInputElement).value;
//                             // get password
//                             const password = (document.getElementById("password") as HTMLInputElement).value;
//                             console.log(username);
//                             if(!verified) {
//                                 print("You're account is not verified by the captcha");
//                                 return;
//                             }
//                             getUser(username, password).then(value => {
//                                 console.log("Ensuite