interface BackgroundProps {
    children: any
    background?: string | undefined
}

const Background = ({children, background}: BackgroundProps) => {
    let opacity = 0.5;
    if(background !== undefined) {
        const backgroundSplit = background?.split(' ')
        if(backgroundSplit !== undefined && backgroundSplit.length > 1) {
            console.log("Found background: "+background)
            background = backgroundSplit[0]
            console.log("Found background: "+background)
            console.log("Test "+ backgroundSplit[1])
            opacity = parseFloat(backgroundSplit[1])
            console.log("Found opacity: "+opacity)
        }
    }


    // check if is in dark mode
    if(document.documentElement.classList.contains('dark')) {
        return (
            <div style={
                {
                    minWidth: "100%",
                    minHeight: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                }
            } className={"overflow-y-hidden relative"}>
                <img src={background} className={"absolute left-0 top-0 w-full h-full object-cover"} style={{opacity: opacity}} alt={"background"}/>
                <div className={"sub-background-dark min-h-screen select-text dark"}>
                    {children}
                </div>
            </div>
        )

    }
    return (

            <div style={
                {

                }
            } className={"overflow-y-hidden relative"}>
            <img src={background} className={"absolute left-0 top-0 w-full h-full object-cover"} style={{opacity: opacity}}/>
            <div className={"sub-background min-h-screen select-text"}>
                {children}
            </div>
            </div>
    )
}

export default Background;