import React, {useEffect, useRef, useState} from "react";
import SessionManager from "../../../../WebSocket/SessionManager";
import {SessionInterface} from "../../../../WebSocket/SessionListener";
import Message from "../../../../WebSocket/Message";
import Session from "../../../../WebSocket/Session";

import {IServiceProps} from "../Services";
import {FaArrowLeft, FaPlay, FaRepeat, FaStop} from "react-icons/fa6";
import Popup from "../../../Utils/Popup";
import {ServicesUtils} from "../../../Utils/ServicesUtils";

export type Service = {
    players: number,
    xmx: number,
    xms: number,
    startup: number,
    name: string,
    type: string,
}


export default function ServicesList(props: IServiceProps) {
    const defaultArray = Array<Service>();
    const popupRef = useRef<any>(null);
    // eslint-disable-next-line no-unused-vars
    const confirmationRef = useRef<any>(null);
    const [countID, setCountID] = useState(1)
    const [customPort, setCustomPort] = useState(false)
    const profiles: string[] = [];
    if(props.executor === undefined) return <></>;
    const executor = props.executor;
    profiles.push("default")
    if(executor.profiles !== undefined){
        executor.profiles.forEach((profile) => {
            profiles.push(profile)
        })
    }

    const startPopup = Popup({children:
           <div>
               <div className={""}>
                   <p className={"font-light"}>Start new services</p>
                   <div className={"flex flex-row"}>
                   <input id="projectBar" className={"inline  border-gray-300 rounded-xl  w-2/4 accent-purple-500"}
                          type={"range"}
                          value={countID}
                          min={1}
                          max={5}
                          step={1}

                          onChange={(event) => {
                              setCountID(parseInt(event.target.value))
                              // document.getElementsByName("projectBackground").style.opacity = (100 - parseInt(document.getElementById("projectBar").value)) / 100
                          }}
                   />
                   <input id={"startID"} className={"w-8 ml-3 text-center"} type={"number"} value={countID} max={100} min={1} onChange={(event) => {
                       let count = parseInt(event.target.value);
                       if(count > 50)
                           count = 50
                       setCountID(count)
                   }}/>
               </div>
                   <p className={"text-sm font-light"}>Advanced Configuration</p>
                   <div className={"w-full h-0.5 bg-gray-300 mt-1 mb-3"}/>
                   <div className={"flex flex-row"}>
                          <p className={"font-light"}>Profile:</p>
                       <select id={"profile"} className={"ml-3 rounded-xl bg-white px-3 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 outline-none"} >
                            {profiles.map((profile) => {
                                 return (
                                      <option value={profile}>{profile}</option>
                                 )
                            })}
                       </select>
                     </div>
                   <div className={"flex flex-row w-full my-1"}>
                       <p className={"font-light"}>Ram MIN:</p>
                       <input id={"ramMin"} className={"w-12 ml-3 text-center   border-2 border-purple-400 bg-neutral-100 rounded-lg font-light"} type={"text"} defaultValue={props.executor?.xms} max={100} min={1} />
                   </div>

                   <div className={"flex flex-row my-1"}>
                       <p className={"font-light"}>Ram MAX:</p>
                       <input id={"ramMax"} className={"w-16 ml-3 text-center border-2 border-purple-400 bg-neutral-100 rounded-lg font-light"} type={"text"} defaultValue={props.executor?.xmx} max={100} min={1} />
                   </div>
                   <div className={"flex flex-row my-1"}>
                       <input id={"is-custom-port"} className={"w-4 h-4 mt-1 "} type={"checkbox"} onChange={event => {
                            setCustomPort(event.target.checked)
                       }}/>
                       <p className={"ml-2 font-light"}>Is custom port ?</p>
                       {customPort &&
                           <>
                           <p className={"ml-5"}>Port</p>
                           <input id={"port"} className={"w-16 ml-3 text-center border-2 border-purple-400 bg-neutral-100 rounded-lg"} type={"number"} defaultValue={props.executor?.port} max={100} min={1} />
                           </>
                       }

                   </div>
                   <div className={"flex flex-row"}>
                       <input id={"is-static"} className={"w-4 h-4 mt-1 "} type={"checkbox"} onChange={() => {
                       }}/>
                          <p className={"ml-2 font-light"}>Is static ?</p>
                   </div>
                   <div className={"w-full h-0.5 bg-gray-300 mt-1 mb-3"}/>

                   <div className={"flex flex-row w-full"}>
                       <button id={"start"} className={"bg-green-500 hover:bg-green-400 rounded-lg py-2 mt-4 text-white mx-auto justify-center w-full"}
                                 onClick={() => {
                                      popupRef.current?.hide()
                                      const profile = document.getElementById("profile")? (document.getElementById("profile") as HTMLInputElement).value : "default";
                                      const xmx = document.getElementById("ramMin")? (document.getElementById("ramMin") as HTMLInputElement).value : "1024M";
                                      const xms = document.getElementById("ramMax")? (document.getElementById("ramMax") as HTMLInputElement).value : "1024M";
                                      const port = document.getElementById("port")? (document.getElementById("port") as HTMLInputElement).value : 0;
                                      const isStatic = document.getElementById("is-static")? (document.getElementById("is-static") as HTMLInputElement).checked : false;

                                     for (let i = 0; i < SessionManager.getSessions().length; i++) {
                                            const session = SessionManager.getSessions()[i];
                                            session.send(new Message().set("instruction", "start").set("executor", props.executor?.name).set("number", countID).set("profile", profile).set("xmx", xmx).set("xms", xms).set("port", port).set("isStatic", isStatic));
                                        }
                                 }}><FaPlay className={"mx-auto inline"}/> Start
                          </button>
                   </div>
               </div>
            </div>,
        name: "Start New " + props.executor?.name, onClose: () => {}, ref: popupRef})

    /*defaultMap.set("bundle1", [
        {bundle: "test", name: "youpi", type: "DYNAMIC", instances: 2, xmx: 2, xms: 2, startup: 2, customName: "test", port: 3, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 0, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "server", type: "STATIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "proxy", type: "DYNAMIC", instances: 0, xmx: 1, xms: 1, startup: 0, customName: "test", port: 1, jvmType: "proxy",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "proxy",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 0, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
    ]);

    defaultMap.set("bundle2", [{bundle: "test2", name: "test2", type: "STATIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test2", port: 1, jvmType: "server" ,players: 2}]);
    defaultMap.set("bundle3", [{bundle: "test2", name: "test2", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test2", port: 1, jvmType: "server" ,players: 2}]);
   // defaultMap.set("test3", [{bundle: "test3", name: "test3", type: "test3", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test3", port: 1, jvmType: "test3"}]);*/
    const [services, setServices] = useState(Array.from(defaultArray));
        

    useEffect(() => {
        SessionManager.getSessionListener().newFrame("services", new class implements SessionInterface {
            onRead(message: Message, session: Session) {
                console.log(message);
                console.log(session);

                if(message.has("instruction")){
                    if(message.get("instruction") === "remove"){
                        const name = message.get("name");
                        for (let i = 0; i < services.length; i++) {
                            const service = services[i];
                            if(service.name === name){
                                services.splice(i,1)
                                setServices([...services])
                                return;
                            }
                        }
                    }
                    if (message.get("instruction") === "add") {
                        const service = ServicesUtils.composeServiceWithMessage(message)
                        if(service === undefined) return;
                        services.push(service)
                        setServices([...services])
                        return;
                    }
                }
            }

            onEnter() {
                console.log("Enter")
                if(props.executor === undefined) return;
                for (let i = 0; i < SessionManager.getSessions().length; i++) {
                     const session = SessionManager.getSessions()[i];
                     session.send(new Message().set("executor", props.executor.name));
                }
            }
        });

    }, []);
    return (
        <div>
            {startPopup}
            <div className={"flex flex-row"}>
            <div id={"goBack"} className={"hover:cursor-pointer ml-5 mt-6 bg-white rounded-lg  py-1 px-1 w-8 h-8 hover:bg-neutral-100"} onClick={() => {
                props.setSelectedExecutor(undefined)
            }
            }>
                <FaArrowLeft className={" mr-2 text-2xl my-5 hover:scale-110 mt-0"} />
            </div>
                <p className={"text-2xl ml-4 mt-5"}>
                        <span className={"hover:rounded-lg hover:bg-neutral-200 hover:cursor-pointer transition-all duration-200"} onClick={() => {
                            props.setService(undefined)
                            props.setSelectedExecutor(undefined)
                        }}>{props.executor?.bundle} </span>
                    /
                    <span className={"rounded-lg bg-neutral-100 hover:cursor-pointer transition-all duration-200"}> {props.executor?.name} </span>
                </p>
            </div>
            <div className={"mx-4 mt-5"}>
        {props.executor !== undefined &&
                <div className={""}>
                    <p className={"text-xl font-normal"}>Services for {props.executor.name}</p>
                    <div className={""}>


                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-4 py-3">
                                        Services
                                    </th>
                                    <th scope="col" className="px-4 py-3">
                                        TYPE
                                    </th>
                                    <th scope="col" className="px-4 py-3">
                                        RAM
                                    </th>
                                    <th scope="col" className="px-3 py-3">
                                        PLAYERS
                                    </th>
                                    <th scope="col" className="w-64 pb-5">
                                        <button className={"bg-green-500 hover:bg-green-400 rounded-lg py-2 mt-4 text-white left-20 ml-2 px-2 "}
                                                onClick={() => {
                                                    for (let i = 0; i < SessionManager.getSessions().length; i++) {
                                                        const session = SessionManager.getSessions()[i];
                                                        session.send(new Message().set("instruction", "simpleStart").set("executor", props.executor?.name));
                                                    }
                                                }}><FaPlay className={"mx-auto inline"}/> Start
                                        </button>
                                        <button className={"bg-primary-500 hover:bg-primary-400 rounded-lg py-2 mt-4 text-white ml-2 px-2"}
                                                onClick={() => {
                                                    popupRef.current?.show()
                                                }}><FaPlay className={"mx-auto inline"}/> Advanced
                                        </button>
                                        <button className={"bg-red-500 hover:bg-red-600 rounded-lg py-2 mt-4 text-white ml-2 px-2"}
                                                onClick={() => {
                                                    for (let i = 0; i < SessionManager.getSessions().length; i++) {
                                                        const session = SessionManager.getSessions()[i];
                                                        session.send(new Message().set("instruction", "stopAll").set("executor", props.executor?.name));
                                                    }
                                                }}><FaStop className={"mx-auto inline"}/> Stop
                                        </button>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>

                                { services.map((service) => {
                                    return (
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 hover:cursor-pointer " onClick={
                                            (event) => {
                                                // check if not button
                                                console.log("target >> "+(event.target as HTMLInputElement).tagName)
                                                if((event.target as HTMLInputElement).tagName === "BUTTON") return;
                                                if((event.target as HTMLInputElement).tagName === "path") return;
                                                if((event.target as HTMLInputElement).tagName === "svg") return;
                                                props.setService(service)
                                            }

                                        }>
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {service.name}
                                            </th>
                                            <td className="px-4 py-4">
                                                {service.type}
                                            </td>
                                            <td className="px-4 py-4">
                                                {service.xms}/{service.xmx}
                                            </td>
                                            <td className="px-4 py-4">
                                                {service.players}
                                            </td>

                                            <td className="px-2 py-4 text-right">
                                                <button className={"bg-orange-400 hover:bg-orange-300 rounded-lg text-white  w-10 h-10 "}
                                                        onClick={() => {
                                                            for (let i = 0; i < SessionManager.getSessions().length; i++) {
                                                                const session = SessionManager.getSessions()[i];
                                                                session.send(new Message().set("service", service.name).set("instruction", "restart"));
                                                            }
                                                        }}><FaRepeat className={"mx-auto"}/>
                                                </button>
                                                <button className={"mx-1 bg-red-500 hover:bg-red-400 rounded-lg text-white  w-10 h-10"}
                                                        onClick={() => {
                                                            for (let i = 0; i < SessionManager.getSessions().length; i++) {
                                                                const session = SessionManager.getSessions()[i];
                                                                session.send(new Message().set("service", service.name).set("instruction", "stop"));
                                                            }
                                                        }}><FaStop className={"mx-auto"}/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
        }
            </div>
        </div>
    )

}