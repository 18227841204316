
import React, {useState} from 'react';
import axios from "axios";
import './ProjectDiv.css'
import {animated, useSpring} from "@react-spring/web";

interface ProjectsDivProps {
    id: number
}


export const ProjectDiv: React.FunctionComponent<ProjectsDivProps> = (props) => {
    const [name, setName] = useState(undefined as any)
    const [logo, setLogo] = useState("/cube.png")
    const spring = useSpring({
        from: {
            opacity: 0,
            transform: "translate3d(0, -50px, 0)"
        },
        to: {
            opacity: 1,
            transform: "translate3d(0, 0px, 0)"
        },
        config: {
            mass:1.5,
            tension: 120,
            friction: 14,
            precision: 0.01,
            velocity: 0,
        }
    })
    const jwt = localStorage.getItem("jwt") as string;
    let data = undefined as any
    if(jwt === null) {
        return <div/>
    }
    console.log("data is "+ data)
    axios.get("https://devnode.dreamnetwork.cloud:8080/v1/project/" + props.id, {
        headers: {
            Authorization: "Bearer " + jwt
        }
    }).then(value => {
        console.log(value.data)
        if(value.data === undefined) {
            return;
        }
        if(value.data.data === undefined) {
            return;
        }
        console.log("value.data.data")

        const projectData =Array.from(value.data.data).at(0) as any
        console.log("Test > "+value.data)
        console.log(projectData)
        window.localStorage.setItem("projectData-"+props.id, JSON.stringify(projectData))
        data = projectData

        const hypervisorData = Array.from(value.data.data).at(1)
        window.localStorage.setItem("hypervisorData-"+props.id, JSON.stringify(hypervisorData))
        setName(data.name)
        console.log(data.logo)
        if(data.logo === null) {
            return;
        }
        if(data.logo === "") {
            return;
        }
        setLogo(data.logo)
    })
    return (
        <div>
        {name === undefined ? <div/> :
        <animated.div style={spring} className={"backcolor block hover:cursor-pointer transition-colors duration-500 m-4 rounded-3xl drop-shadow-lg"}>
            <div className={"flex flex-col justify-center mt-4 pt-2 "}>
                <p className={"text-2xl font-mono text-center font-bold text-blue-950"}>{name}</p>
                <div className={"flex flex-row justify-center my-2"}/>
            </div>
        <div onClick={() => {
               window.location.href = "/dashboard/"+props.id
        }} className={"bg-white block  rounded-xl "}>
            <div className={"test"}>
                <img className={"image backcolor mx-auto items-center w-52 h-40  "} src={logo} alt={"logo"}/>
            </div>

            <div className={"bg-gray-200 block  rounded-b-xl"}></div>
        </div>

        </animated.div>
        }
        </div>

    );
}