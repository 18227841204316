import React, {useState} from "react";
import Navbar from "../Navbar/Navbar";

import "./Background.css"
import Popup from "../Utils/Popup";
import {createProject} from "../../RestCommunication/CreateProject";
import {ToastContainer} from "react-toastify";
import {ProjectDiv} from "../Utils/ProjectDiv";

export  interface IProjectsProps {}

const listProjects = (user: any) => {
    const projects = user.projects
    return projects.map((project: any) =>
        <ProjectDiv id={project}/>
    )
}

// eslint-disable-next-line no-unused-vars
const ProjectsPage: React.FunctionComponent<IProjectsProps> = (props) => {
    const user = JSON.parse(localStorage.getItem("userData") as string);
    const [activatePopup, setActivatePopup] = useState(false)


    if(user === null) {
        return (
            <div className={"min-h-screen sub-background"}>
                <Navbar/>
                <div className={"flex flex-col items-center justify-center"}>
                    <p className={"text-2xl font-sans"}>You are not connected</p>
                </div>
            </div>
        )
    }

    let projectsTitle = "Ready to start 👋😎 ?"

    if(user.projects.length > 0) {
        projectsTitle = "Your dreams projects: 😎"
    }
    const projectSize = user.projects.length;

    return(

        <div className={"min-h-screen sub-background overflow-y-auto"}>
            <ToastContainer/>
            <Popup name={"Create your Network"} isActive={activatePopup} onClose={() => {setActivatePopup(false)}}>
                <div className={"flex flex-col items-center justify-center"}>
                    <div className={"flex flex-col items-center justify-center"}>
                        <p className={"text-2xl font-sans"}>Name your network</p>
                        <input id="projectName" className={"border-2 border-gray-300 rounded-xl p-2 mt-4"} type={"text"} placeholder={"Ex: MySuperNetwork"}/>
                        <button className={"bg-purple-400 hover:bg-purple-500 rounded-xl p-2 mt-4 text-white"} onClick={() => {
                            const projectName = (document.getElementById("projectName") as HTMLInputElement).value
                            if(projectName === "") {
                                alert("Your project name is empty")
                                return;
                            }
                            if(projectName.length < 3) {
                                alert("Your project name is too short")
                                return;
                            }
                            const token = localStorage.getItem("jwt")
                            createProject(token, projectName).then(value  => {
                                if(value === undefined) {
                                    return;
                                }
                                console.log(value)
                                console.log(value.status)
                                if(value.status === 200) {
                                    setActivatePopup(false)
                                    window.location.reload()
                                }
                            })
                        }}>Create</button>
                        <div className={"flex flex-row justify-center mt-4"}/>
                    </div>
                </div>
            </Popup>
            <Navbar/>
            <div className={"flex-row w-full mx-auto flex pt-[10%] pr-20 text-2xl u text-center"}>
                <p className={"justify-center ml-[43%] bg-white rounded-xl px-5"}>{projectsTitle}</p>
                <p className={"justify-end ml-auto"}>Welcome {user.nickname} </p>
            </div>

            {projectSize > 0 ? (
                <div>
                 <div className={"flex flex-row justify-center"}>
                     <p>ok.</p>
                     <p>Here is your projects</p>
                 </div>
                <div className={"flex flex-wrap pt-4 text-2xl mx-auto justify-center w-3/4 "}>
                    {listProjects(user)}
                    <div>
                        <div onClick={() => {
                            setActivatePopup(true)
                        }} className={""}>
                            <div className={"bg-white rounded-xl  mt-6 p-4  border-dotted border-2 hover:bg-gray-100 hover:cursor-pointer"}>
                                <div className={"flex flex-col items-center justify-center"}>
                                    <img src={"/cube.png"} className={"w-32 h-32 "}/>
                                    <p className={"text-2xl"}>+</p>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            ) : (
                <div onClick={() => {
                    setActivatePopup(true)
                }} className={"mt-10 w-11/12 min-h-11/12 mx-auto"}>
                    <div className={"bg-white rounded-xl min-h-11/12 p-8 border-dotted border-2 pt-24 hover:bg-gray-100 hover:cursor-pointer"}>
                        <div className={"flex flex-col items-center justify-center"}>
                            <img src={"/cube.png"} className={"w-32 h-32"}/>
                            <p className={"text-2xl"}>Create a new network</p>
                        </div>
                        <div className={"pt-24"}>
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
};


export default ProjectsPage;