import axios from "axios";

export const getUser = async (token: string | null) => {
    if(token == null){
        localStorage.removeItem("jwt");
        localStorage.removeItem("userData");
        return;
    }
    axios.defaults.timeout = 10000;
    try {
        axios.defaults.timeout = 10000;
        const id = axios.interceptors.response.use(response => {
            axios.interceptors.response.eject(id);
            return response;
        }, error => {

            axios.interceptors.response.clear();
            if (error.response != null && error.response.data.message != null) {
                return Promise.resolve();
            }

            window.location.href = "/auth/login";
            axios.interceptors.response.eject(id);
            return Promise.reject(error);
        });

        const response = await axios.get("https://devnode.dreamnetwork.cloud:8080/v1/user", {
        headers: {
            Authorization: 'Bearer ' + token
        }


});

    if(response == null) {
        return;
    }

    response.config.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000';
    response.config.headers['Content-Type'] = 'application/json';


    if(response.status != null && response.status === 401){
        return;
    }

    if(response.data == null){
        localStorage.removeItem("jwt");
        localStorage.removeItem("userData");
        return;
    }

    if(response.data.data == null){
        return;
    }

    if(response.data.message === "Unauthorized"){
        localStorage.removeItem("jwt");
        localStorage.removeItem("userData");
        return;
    }

    if(response.data.message === "User not found"){
        localStorage.removeItem("jwt");
        localStorage.removeItem("userData");
        return;
    }


    return response.data.data;
}catch (e) {
        console.log(e)

    }
}


