import Content from "../Content/Content";
import CenterContent from "../Content/CenterContent";
import React, {useEffect, useState} from "react";
import SessionManager from "../../../../WebSocket/SessionManager";
import {SessionInterface} from "../../../../WebSocket/SessionListener";
import Message from "../../../../WebSocket/Message";
import Session from "../../../../WebSocket/Session";

import NetworkChart, {NetworkChartType} from "../../../Charts/NetworkChart";
import {CardHover} from "../../../Charts/CardHover";
import CircleChart from "../../../Charts/CPUChart";
import PlayerChart, {PlayersChartType} from "../../../Charts/PlayersChart";

interface ViewConnectionsProps {
    connections: any

}

// eslint-disable-next-line no-unused-vars
export default function ViewConnections(props: ViewConnectionsProps) {
    // eslint-disable-next-line no-unused-vars
    const [ramUsage, setRamUsage] = useState(0);
    const [ramTotal, setRamTotal] = useState(0.01);
    const [diskFree, setDiskFree] = useState(0.01);
    const [diskTotal, setDiskTotal] = useState(0.01);
    const [cpuUsage, setCpuUsage] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [networkUsageIN, setNetworkUsage] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [networkUsagePerSecondIN, setNetworkUsagePerSecond] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [networkUsagePerMinuteIN, setNetworkUsagePerMinute] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [networkUsageOUT, setNetworkUsageOUT] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [networkUsagePerSecondOUT, setNetworkUsagePerSecondOUT] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [networkUsagePerMinuteOUT, setNetworkUsagePerMinuteOUT] = useState(0);
    const [networkUsagePerSecondListOUT , setNetworkUsagePerSecondListOUT] = useState([] as number[]);
    const [networkUsagePerSecondListIN , setNetworkUsagePerSecondListIN] = useState([] as number[]);
    const [networkUsagePerMinuteListOUT , setNetworkUsagePerMinuteListOUT] = useState([] as number[]);
    const [networkUsagePerMinuteListIN , setNetworkUsagePerMinuteListIN] = useState([] as number[]);

    //eslint-disable-next-line
    const [playersMin, setPlayersMin] = useState([] as number[]);
    //eslint-disable-next-line
    const [playersHour, setPlayersHour] = useState([] as number[]);

    const [networkChartType, setNetworkChartType] = useState("seconds");
    //eslint-disable-next-line
    const [playerChartType, setPlayerChartType] = useState("minutes");


    useEffect(() => {

        SessionManager.getSessionListener().newFrame("overview", new class implements SessionInterface {
            onEnter() {
                console.log("ViewConnections entered")
            }

            onRead(message: Message, session: Session) {
                console.log("ViewConnections got message ! ")
                console.log(message + "on "+ session.node)

                if(message.has("ramUsage")) {
                    setRamUsage(message.get("ramUsage") / (1024 * 1024));
                }
                if(message.has("ramTotal")){
                    setRamTotal(message.get("ramTotal") / (1024 * 1024));
                }

                if(message.has("diskFree")){
                    setDiskFree(message.get("diskFree") / (1024 * 1024 * 1024));
                }
                if(message.has("diskTotal")){
                    setDiskTotal(message.get("diskTotal") / (1024 * 1024 * 1024));
                }
                if(message.has("cpuUsage")){
                    setCpuUsage(message.get("cpuUsage"));
                }
                if(message.has("networkUsageIN")){
                    setNetworkUsage(message.get("networkUsageIN")/ (1024 * 1024));
                }
                if(message.has("networkUsagePerSecondIN")){
                    setNetworkUsagePerSecond(message.get("networkUsagePerSecondIN")/ (1024));
                    //setNetworkUsagePerSecondListIN(networkUsagePerSecondListIN => [...networkUsagePerSecondListIN, message.get("networkUsagePerSecondIN")/ (1024)]);
                }

                if(message.has("networkUsagePerMinuteIN")){
                    setNetworkUsagePerMinute(message.get("networkUsagePerMinuteIN")/ (1024));
                    //setNetworkUsagePerMinuteListIN(networkUsagePerMinuteListIN => [...networkUsagePerMinuteListIN, message.get("networkUsagePerMinuteIN")/ (1024)]);
                }
                if(message.has("networkUsageOUT")){
                    setNetworkUsageOUT(message.get("networkUsageOUT")/ (1024 * 1024));
                }
                if(message.has("networkUsagePerSecondOUT")){
                    setNetworkUsagePerSecondOUT(message.get("networkUsagePerSecondOUT")/ (1024));
                    //setNetworkUsagePerSecondListOUT(networkUsagePerSecondListOUT => [...networkUsagePerSecondListOUT, message.get("networkUsagePerSecondOUT")/ (1024)]);
                }
                if(message.has("networkUsagePerMinuteOUT")){
                    setNetworkUsagePerMinuteOUT(message.get("networkUsagePerMinuteOUT")/ (1024));
                    // setNetworkUsagePerMinuteListOUT(networkUsagePerMinuteListOUT => [...networkUsagePerMinuteListOUT, message.get("networkUsagePerMinuteOUT")/ (1024)]);
                }
                if(message.has("HEAD")){
                    if(message.has("type")){
                        if(message.get("type") == "in"){
                            if(message.get("time") == "seconds"){
                                setNetworkUsagePerSecondListIN(networkUsagePerSecondListIN => [...networkUsagePerSecondListIN, message.get("b")]);
                            }else {
                                setNetworkUsagePerMinuteListIN(networkUsagePerMinuteListIN => [...networkUsagePerMinuteListIN, message.get("b")]);
                            }
                        }else{
                            if(message.get("time") == "seconds"){
                                setNetworkUsagePerSecondListOUT(networkUsagePerSecondListOUT => [...networkUsagePerSecondListOUT, message.get("bytes")]);
                            }else {
                                setNetworkUsagePerMinuteListOUT(networkUsagePerMinuteListOUT => [...networkUsagePerMinuteListOUT, message.get("bytes")]);
                            }
                        }
                    }
                }

                if(message.has("bytesINSec")){
                    setNetworkUsagePerSecondListIN(message.get("bytesINSec"));
                }
                if(message.has("bytesOUTSec")){
                    setNetworkUsagePerSecondListOUT(message.get("bytesOUTSec"));
                }

                if(message.has("bytesINMin")){
                    setNetworkUsagePerMinuteListIN(message.get("bytesINMin"));
                }

                if(message.has("bytesOUTMin")) {
                    setNetworkUsagePerMinuteListOUT(message.get("bytesOUTMin"));
                }

                if(message.has("playersMin")){
                    setPlayersMin(message.get("playersMin"));
                }
                if(message.has("playersHour")){
                    setPlayersHour(message.get("playersHour"));
                }
            }
        });
    })

    return (
        <>
            <Content>
                {/*<LeftContent>*/}
                <CenterContent isFull={true}>
                    <div className=" select-none">
                        <div className={"mt-4"}>
                            <div className={"flex max-sm:flex-col sm:flex-col xl:flex-row"}>
                            <div className={"w-5/6 py-2 mx-5 bg-neutral-100 rounded-xl justify-center items-center"}>
                                <p className={"ml-2 text-2xl font-bold"}>Players</p>
                                <CardHover className={"ml-10"} moveMultiplier={0} backgroundCircle={false}>
                                    <select className={"bg-neutral-100 rounded-xl float-right"} onChange={(event) => {
                                        setPlayerChartType(event.target.value);
                                    }}>
                                        <option value="minutes">Minutes</option>
                                        <option value="hours">Hours</option>
                                    </select>
                                    { playerChartType == "minutes" ?
                                        <PlayerChart type={PlayersChartType.MINUTES} list={playersMin}/>
                                        :
                                        <PlayerChart type={PlayersChartType.HOURS} list={playersHour}/>
                                    }
                                </CardHover>
                            </div>
                            <div className={" bg-neutral-50 mx-3"}>
                                <p className={"ml-2 text-2xl font-bold"}>Stats</p>
                                <CardHover moveMultiplier={0.7} backgroundCircle={true}  className={"m-3"}>
                                    <div className={"rounded-2xl  hover:scale-105 transition-all duration-300 hover:drop-shadow-xl "}>
                                        <CircleChart name={"CPU"} value={((cpuUsage)*100)} textRendering={(cpuUsage*100).toFixed(0) + "%"} subText={""} />
                                    </div>
                                </CardHover>
                                <CardHover moveMultiplier={0.7} backgroundCircle={true} >
                                    <div className={"rounded-2xl  hover:scale-105 transition-all duration-300 hover:drop-shadow-xl"} style={
                                        {
                                            maxWidth: "210px",
                                            maxHeight: "180px",
                                        }
                                    }>
                                        <CircleChart name={"RAM"} value={(((ramUsage/ramTotal)*100))} textRendering= {(((ramUsage/ramTotal)*100)).toFixed(0) + "%"} subText={"of "+(ramTotal/1024).toFixed(0)+"GB" } />
                                    </div>
                                </CardHover>
                                <CardHover moveMultiplier={0.7} backgroundCircle={true}  >
                                    <div className={"rounded-2xl hover:scale-105 transition-all duration-300 hover:drop-shadow-xl"}>
                                        <CircleChart name={"DISK"} value={((diskTotal-diskFree)*100/diskTotal)} textRendering={((diskTotal-diskFree)*100/diskTotal).toFixed(0) + "%"} subText={"of "+(diskTotal).toFixed(0)+"GB" } />
                                    </div>
                                </CardHover>


                            </div>
                            </div>






                                        <div className={"static"}>
                                            <p className={"ml-2 text-2xl font-bold"}>Network</p>
                                            <CardHover moveMultiplier={0.2} backgroundCircle={false}  >
                                                <select className={"bg-neutral-100 rounded-xl float-right"} onChange={(event) => {
                                                    setNetworkChartType(event.target.value);
                                                }}>
                                                    <option value="seconds">Seconds</option>
                                                    <option value="minutes">Minutes</option>
                                                </select>
                                                { networkChartType == "seconds" ?
                                                    <NetworkChart type={NetworkChartType.SECONDS} listInbound={networkUsagePerSecondListIN} listOutbound={networkUsagePerSecondListOUT}/>
                                                    :
                                                    <NetworkChart type={NetworkChartType.MINUTES} listInbound={networkUsagePerMinuteListIN} listOutbound={networkUsagePerMinuteListOUT}/>
                                                }
                                            </CardHover>
                                        </div>
                                    </div>
                    </div>
                </CenterContent>

            </Content>

        </>
    );




}