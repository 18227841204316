
import React from "react";
import {
    //LineChart,
   // Line,
    XAxis,
    YAxis,
    //CartesianGrid,
    Tooltip,
    Legend, AreaChart, Area
} from "recharts";


export enum PlayersChartType {
    // eslint-disable-next-line no-unused-vars
    MINUTES,
    // eslint-disable-next-line no-unused-vars
    HOURS
}



// eslint-disable-next-line no-unused-vars
function toFixedNumber({num, digits, base}: { num: any, digits: any, base: any }){
    const pow = Math.pow(base ?? 10, digits);
    return Math.round(num*pow) / pow;
}
function generate(type: PlayersChartType, list: number[]) : {name: string, players: number}[] {
    const data = [] as {name: string, players: number}[]
    const length = list.length
        // longer stat

        const typeString = type === PlayersChartType.MINUTES ? "m" : "h"
        for (let i = 0; i < length; i++) {
            if(i === list.length-1) {
                data.push({
                    name: "now",
                    players: list[i] == undefined ? 0 : toFixedNumber({num: list[i], digits: 2, base: 10}),
                })
                continue;
            }
            data.push({
                name: list.length-(i+1)+ typeString,
                players: list[i] == undefined ? 0 : toFixedNumber({num: list[i], digits: 2, base: 10}),
            })
        }

    return data
}
// eslint-disable-next-line no-unused-vars
const data = [
    {
        name: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100
    }
];

interface IPlayerChartProps {
    type: PlayersChartType
    list: number[]
}
export default function PlayerChart({type, list}: IPlayerChartProps) {
    return (
        <div style={
            {
                //backgroundColor: "rgba(255, 255, 255, 0.65)",
            }
        } className={"m-2 pt-5 rounded-2xl transition-all duration-300 "}>
            <div className={"inline-block"}>
                <AreaChart className={" dark:bg-black rounded-2xl"}
                           width={650}
                           height={300}
                           data={generate(type, list)}
                           margin={{
                               top: 10,
                               right: 30,
                               left: 20,
                               bottom: 5
                           }}

                >
                    {/*<CartesianGrid strokeDasharray="3 3" />*/}
                    <XAxis dataKey="name" />
                    <YAxis unit={""} domain={[0, 10]}/>
                    <Tooltip />
                    <Legend />
                    <Area
                        type="monotone"
                        dataKey="players"
                        stroke="#4287f5"
                        unit={" player(s)"}
                        fill={"rgba(66, 135, 245,0.5)"}
                        activeDot={{ r: 6 }}
                    />

                </AreaChart>
            </div>


        </div>

    );
}
