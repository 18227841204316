import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import ProjectsPage from "./components/Dashboard/Pages/Projects";
import DashboardPage from "./components/Dashboard/Pages/Dashboard/Dashboard";
import AccountPage from "./components/Dashboard/Pages/Account";
import SettingsPage from "./components/Dashboard/Pages/Settings";
import LoadingScreen from "./components/Loading/LoadingScreen";
import ContentAuthPage,{AuthState} from "./components/Auth/ContentAuthPage";
import PrivateRoutes from "./components/Auth/PrivateRoutes";
import Terms from "./components/Auth/Terms";
import NotFound from "./components/NotFound/NotFound";


export interface IApplicationProps {}

// eslint-disable-next-line no-unused-vars
const App: React.FunctionComponent<IApplicationProps> = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 2000)
        const path = window.location.pathname;
        if(path === '/auth/login' || path === '/auth/register' || path === '/auth/forgot-password' || path === '/auth/success-register') {
            setLoading(true);
            return;
        }
        document.addEventListener('load', () => {
            // Maintenant, les images devraient être chargées
            const images = document.getElementsByTagName("img");
            console.log("Found " + images.length + " images");

            const promises: Promise<void>[] = [];

            for (let i = 0; i < images.length; i++) {
                const promise = new Promise<void>((resolve, reject) => {
                    images[i].addEventListener('load', () => resolve());
                    images[i].addEventListener('error', () => reject());
                });

                promises.push(promise);
            }

// and background images too
            const cssImages = document.getElementsByTagName("div");
            console.log("Found " + cssImages.length + " css images");
            for (let i = 0; i < cssImages.length; i++) {
                const promise = new Promise<void>((resolve, reject) => {
                    cssImages[i].addEventListener('load', () => resolve());
                    cssImages[i].addEventListener('error', () => reject());
                });
                promises.push(promise);
            }

            Promise.all(promises)
                .then(() => {
                    console.log("Toutes les images ont été chargées avec succès");
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        });



    }, [])
    /*if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark')
    }*/
    // get current path

    return (
        <>
            {loading && <LoadingScreen />}
            {
                <BrowserRouter >
                    <>
                            <Routes >
                                <Route element={<PrivateRoutes/>}>
                                    <Route path={'/'} element={<ProjectsPage/>}/>
                                    <Route path={'/dashboard'}>
                                        <Route index element={<DashboardPage />} />
                                        <Route path={":number"}>
                                            <Route index element={<DashboardPage />} />
                                            <Route path={"settings"} element={<SettingsPage />} />
                                        </Route>
                                    </Route>
                                    <Route path={'/account'} element={<AccountPage/>}/>
                                </Route>



                                <Route path={'/auth/login'} element={<ContentAuthPage state={AuthState.LOGIN} />}/>
                                <Route path={"/terms"} element={<Terms/>}/>
                                <Route path={'*'} element={<NotFound/>}/>
                            </Routes>
                    </>

                </BrowserRouter>
            }
        </>
    );
}

export default App;