import React, {useEffect} from "react";
import { ToastContainer} from "react-toastify";
import Navbar from "../Navbar/Navbar";


export  interface IDashboardProps {}

// eslint-disable-next-line no-unused-vars
const AccountPage: React.FunctionComponent<IDashboardProps> = (props) => {
    const account = JSON.parse(localStorage.getItem("userData") as string);

    useEffect(() => {

    },[]);

    const creationDate = new Date(account.creationDate);
    const lastConnection = new Date(account.lastConnection);
    return(
        <div className={"min-h-screen sub-background overflow-y-auto"}>
            <ToastContainer/>
            <Navbar isInProject={false}/>
            <div
                className={"bg-white dark:bg-black mt-7 sm:w-10/12 lg:w-8/12 h-3/4 my-auto mx-auto border border-dotted drop-shadow-md rounded-lg"}>
                <p className={"text-2xl font-sans ml-4 mt-5"}>Your account 😎</p>
                {/* create a line*/}
                <div className={"bg-gray-50 w-full mt-4 p-[1px]"}/>


                <div className={"overflow-y-auto"}>

                    <div className={"flex bg-neutral-100 dark:bg-gray-900 p-4"}>
                        <p className={"font-light pl-8 absolute"}>Email</p>
                        <p className={"max-sm:ml-48 md:ml-48"}>{account.username}</p>
                    </div>
                    <div className={"flex bg-white dark:bg-black p-4 "}>
                        <p className={"font-light pl-8 absolute"}>Nickname</p>
                        <p className={"max-sm:ml-48 md:ml-48"}>{account.nickname}</p>
                    </div>

                    <div className={"flex bg-neutral-100 dark:bg-gray-900 p-4 "}>
                        <p className={"font-light pl-8 absolute"}>User ID</p>
                        <p className={"max-sm:ml-48 md:ml-48"}>{account.id}</p>
                    </div>

                    <div className={"flex bg-white dark:bg-black p-4 "}>
                        <p className={"font-light pl-8 absolute"}>Created at</p>
                        <p className={"max-sm:ml-48 md:ml-48"}>{creationDate.toLocaleDateString()} - {creationDate.toLocaleTimeString()}</p>
                    </div>

                    <div className={"flex bg-neutral-100 dark:bg-gray-900 p-4"}>
                        <p className={"font-light pl-8 absolute"}>Last connection</p>
                        <p className={"max-sm:ml-48 md:ml-48"}>{lastConnection.toLocaleDateString()} - {lastConnection.toLocaleTimeString()}</p>
                    </div>

                    <div className={"flex bg-white dark:bg-black p-4 "}>
                        <p className={"font-light pl-8 absolute"}>Role</p>
                        <p className={"max-sm:ml-48 md:ml-48"}>{account.role}</p>
                    </div>

                    <div className={"flex bg-neutral-100 dark:bg-gray-900 p-4"}>
                        <p className={"font-light pl-8 absolute"}>Current offer</p>
                        <p className={"max-sm:ml-48 md:ml-48"}>{account.offer}</p>
                    </div>

                    <p className={"text-2xl font-sans ml-4 mt-5"}>Customization 🍿</p>

                    <div className={"flex bg-neutral-100 dark:bg-gray-900 p-4 "}>
                        <p className={"font-light pl-8 absolute"}>Profile picture</p>

                        <input type={"text"} className={"max-sm:ml-48 md:ml-48 w-64 border-2 rounded-xl px-2"}
                               placeholder={"Username/URL/Nothing"}/>
                        <p className={"ml-3 text-sm text-center text-neutral-600"}>Type your MC username, a custom link or leave it empty to use your gravatar</p>
                    </div>


                </div>
            </div>
        </div>

    );
};

export default AccountPage;