import React from 'react';
import './LoadingScreen.css';
import {animated, useSpring} from "@react-spring/web";

const LoadingScreen = () => {
    const springs = useSpring({
        from: { x: 1, zoom:1},
        to: { x: 0,
            zoom: 1.1,
        },

        config: {
            mass:2,
            tension: 140,
            friction: 14,
            precision: 0.01,
            velocity: 0,
        },
        loop: {
            reverse: true
        }
    })
    const walk = useSpring({
        from: { x: 0 },
        to: { x: 50 },
        loop: {
            reverse: true
        },
        config: {
            mass:200,
            tension: 140,
            friction: 14,
            precision: 0.01,
            velocity: 0.1,
        },

    })

    //eslint-disable-next-line
    const cloud1 = useSpring({
        from: { x: 0 },
        to: { x: -1918 },
        loop: true,
        delay: 3500,
        config: {
            duration: 7000,
        },
        immediate: true,
    })
    //eslint-disable-next-line
    const cloud2 = useSpring({
        from: { x: 0 },
        to: { x: -1920 },
        loop: true,
        delay: 1500,
        config: {
            duration: 16000,
        },
    })
    //eslint-disable-next-line
    const cloud3 = useSpring({
        from: { x: 0 },
        to: { x: -1920 },
        loop: true,
        delay: 4000,
        config: {
            duration: 11000,
        },
    })
    //eslint-disable-next-line
    const cloud4 = useSpring({
        from: { x: 0 },
        to: { x: -1920 },
        loop: true,
        delay: 0,
        config: {
            duration: 10000,
        },
    })
    //eslint-disable-next-line
    const cloud5 = useSpring({
        from: { x: -700 },
        to: { x: -1918 },
        config: {
            duration: 12000,
        },
    })
    //eslint-disable-next-line
    const cloud6 = useSpring({
        from: { x: -300 },
        to: { x: -1918 },
        config: {
            duration: 10000,
        },
    })
    const back1 = useSpring({
        from: { x: 0, y: 0},
        to: { x: -1918, y :0 },
        config: {
            duration: 10000,
        },
        loop: true,
    })
    //eslint-disable-next-line
    const back2 = useSpring({
        from: { x: 1918, y: 0},
        to: { x: -1918, y: 0 },
        loop: true,
        config: {
            duration: 20000,
        },

    })
    //eslint-disable-next-line
    const back3 = useSpring({
        from: { x: 1918*2, y: 0},

        to: { x: -1918, y: 0 },
        loop: true,
        config: {
            duration: 30000,
        },
    })

     return (
         <div className={"loadBack"}>
             {/*

             <animated.div style={cloud1}>
                 <object type={"image/svg+xml"} data={"/svg/Cloud1.svg"} className={"absolute h-40 w-40 left-full"}></object>
             </animated.div>
             <animated.div style={cloud2}>
                 <object type={"image/svg+xml"} data={"/svg/Cloud2.svg"} className={"absolute h-32 w-32 mt-24 left-full"}></object>
             </animated.div>
                <animated.div style={cloud3}>
                    <object type={"image/svg+xml"} data={"/svg/Cloud3.svg"} className={"absolute h-40 w-40 mt-44 left-full"}></object>
                </animated.div>
             <animated.div style={cloud4}>
                 <object type={"image/svg+xml"} data={"/svg/Cloud4.svg"} className={"absolute h-40 w-40 mt-16 left-full"}></object>
             </animated.div>
             <animated.div style={cloud5}>
                 <object type={"image/svg+xml"} data={"/svg/Cloud2.svg"} className={"absolute h-40 w-40 mt-16 left-full"}></object>
             </animated.div>
                <animated.div style={cloud6}>
                    <object type={"image/svg+xml"} data={"/svg/Cloud3.svg"} className={"absolute h-52 w-52 mt-44 left-full"}></object>
                </animated.div>
        */}
             <animated.div className={"absolute w-full test"} style={back1}>
                 <object type={"image/svg+xml"} data={"/svg/Background.svg"}  style={
                        {
                            //50% of the screen
                            bottom: "300%",
                            //slide transition to the left
                            left: "0%",
                            zIndex: 0,
                        }
                 }></object>

             </animated.div>

             <animated.div className={"absolute w-full test z-50"} style={back2}>
                 <object type={"image/svg+xml"} data={"/svg/Background.svg"}  style={
                     {
                         //50% of the screen
                         bottom: "300%",
                         //slide transition to the left
                         left: "0%",
                         zIndex: 0,
                     }
                 }></object>


             </animated.div>
             <animated.div className={"absolute w-full test"} style={back3}>
                 <object type={"image/svg+xml"} data={"/svg/Background.svg"}  style={
                     {
                         //50% of the screen
                         bottom: "300%",
                         //slide transition to the left
                         left: "0%",
                     }
                 }></object>
             </animated.div>




  <div className="loading-screen">
      <div className="loading-spinner"></div>

     <div className="absolute z-10">
          <animated.div className={"z-10"} style={walk}>
              <object type={"image/svg+xml"} data={"/svg/Loading.svg"} className={"mb-20 h-96 w-56 z-10"}></object>


      </animated.div>

     </div>

     

      <div className=" font-bold text-xl mb-80 opacity-100 to-transparent transition-colors">
          <animated.div
              style={{
                  ...springs,
              }}>

          <h1 className={"text-white"}>Loading...</h1>
          <div className="mx-auto px-44 bg-white py-0.5"></div>
          </animated.div>
      </div>



  </div>
  </div>
);
};

export default LoadingScreen;