import axios, {AxiosResponse} from "axios";

interface RequestBuilderState {
    api: string;
    hasJWT: boolean;
    jwt?: string | null;
    body: { [key: string]: any }
}
type RequestType = "GET" | "POST";
export class RequestBuilder {
    state : RequestBuilderState;
    requestType: RequestType;
    constructor(RequestType: RequestType, endpoint: string, jwt: boolean) {
        this.state = { api: "https://devnode.dreamnetwork.cloud:8080" + endpoint, hasJWT: jwt, body: {}, jwt: null};
        this.requestType = RequestType;
        if(this.state.hasJWT) {
            const jwt = localStorage.getItem("jwt");
          /*  if(jwt == null) {
                localStorage.removeItem("jwt");
                localStorage.removeItem("userData");
                window.location.href = "/auth/login";
                return
            }*/
            this.state.jwt = jwt;
        }
    }

    setToken(token: string) {
        this.state.jwt = token;
        return this;
    }

    setBody(body: any) {
        this.state.body = body;
        return this;
    }

    setApi(api: string) {
        this.state.api = api;
        return this;
    }

    public async sendRequest() {
        if(this.requestType === "GET") {
            return await this.sendGetRequest();
        } else if(this.requestType === "POST") {
            return await this.sendPostRequest();
        }
    }

    private async prepareRequest() {
        axios.defaults.timeout = 10000;
        try {
            axios.defaults.timeout = 10000;
           const id = axios.interceptors.response.use(response => {
               axios.interceptors.response.eject(id);
                return response;
            }, error => {
               console.log(error)
                console.log(error.response)
                console.log("Message >>" + error.response.data.message)
                console.log(error.response.status)
                // toast.warning("The project could not be created");

                axios.interceptors.response.eject(id);
                if (error.response != null && error.response.data.message != null) {
                    return Promise.resolve();
                }
                return Promise.reject(error);
            });
        }
        catch (e) {
            console.log(e);
        }
    }
    private async sendGetRequest() {
        try {
            this.prepareRequest();
            console.log("Hey")
            let config = {};
            console.log(this.state.body)
            if(this.state.hasJWT) {
                 config = {
                    headers: {
                        Authorization: 'Bearer ' + this.state.jwt
                    },
                     params: this.state.body
                };
                 console.log("Config 1 => "+config)


                    console.log("Config 2 => "+config)
            }



            console.log(this.state.api)

            console.log("Hey")
            console.log("Config => "+config)

            const response = await axios.get(this.state.api, config);
            response.config.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000';
            response.config.headers['Content-Type'] = 'application/json';
            return new RequestResponse(response, null, response.status);
        }catch (e) {
            return new RequestResponse(null, e);
        }


    }

    private async sendPostRequest() {
        try {
            this.prepareRequest();
            let config = {};
            if(this.state.hasJWT) {
                config = {
                    headers: {
                        Authorization: 'Bearer ' + this.state.jwt
                    }
                }
            }
            const response = await axios.post(this.state.api, this.state.body, config);
            return new RequestResponse(response.data, null, response.status);
        }catch (e) {
            return new RequestResponse(null, e);
        }
    }

}
class RequestResponse {
    private response: AxiosResponse | null;
    private status : any;
    private error: any;
    constructor(response: AxiosResponse | null, error: any, status?: any) {
        console.log("Hey Constructor")
        this.response = response;
        this.error = error;
        this.status = status;
        if(response == null && error == null) {
            error = "No response and no error";
        }

        if(response != null && response.status != null && response.status === 401){
            console.log("401?")
            //localStorage.removeItem("jwt");
            //localStorage.removeItem("userData");
          //  window.location.href = "/auth/login";
        }
    }

    public getResponse() {
        return this.response;
    }

    public getError() {
        return this.error;
    }

    public getStatus() {
        return this.status;
    }
    /* eslint-disable no-unused-vars */
    public onError(callback: (error: any) => void) {
        callback(this.error);
    }


     public onSuccess(callback: (response: any) => void) {
        if(this.response !== null && this.response.status !== null && this.response.status === 200) {
            callback(this.response.data);
        }
    }

    public onFailed(callback: (response: any) => void) {
        if(this.response == null) {
            callback(this.response);
        }
    }
    public onResponse(callback: (response: any) => void) {
        callback(this.response);
    }

    public onNotFound(callback: () => void) {
        if(this.response?.status != null && this.response.status === 404) {
            callback();
        }
    }

    public onUnauthorized(callback: () => void) {
        if(this.response !== null && this.response.status != null && this.response.status === 401) {
            callback();
        }
    }
}