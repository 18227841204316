import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';

import "./Navbar.css";

import sha256 from 'crypto-js/sha256';
import {WindowEventListener} from "../Utils/WindowEventListener";


interface INavbarProps {
    // Props here
    isInProject?: boolean
    projectName?: string
    projectIcon?: string
    showProjectIcon?: boolean
    id?: string
    selected?: number
    onSettingsClick?: any
}
const Navbar: React.FC<INavbarProps> = (props)  => {
    const [accountLeft, setAccountLeft] = useState(0)
    const initialized = useRef(false)
    const navigate = useNavigate();

    const updateAccountLeft = () => {
        const accountButton = document.getElementById("accountButton");
        if(accountButton !== null) {
            setAccountLeft(accountButton.getBoundingClientRect().left-40);
        }
    }
    // set effect 

    if(localStorage.getItem("jwt") === null ) {
        return <></>
    }
    const user = JSON.parse(localStorage.getItem("userData") as string);
    const sha256Mail = sha256(user.email).toString();
    let projectIcon: string | undefined = "/cube.png"
    if(props.projectIcon !== null) {
        projectIcon = props.projectIcon
    }


    useEffect(() => {
        const onPageLoad = () => {
            console.log('page loaded');
            // do something else

            updateAccountLeft();
            WindowEventListener.registerEventListener("resizeNav","resize", updateAccountLeft)
            console.log("use effect")
            // readdd invisible class on modal on click outside one time

            WindowEventListener.registerEventListener("click_nav","click", (event) => {
                updateAccountLeft();
                const accountButton = document.getElementById("accountButton");
                const modal = document.getElementById("accountModal");

                if(modal !== null) {


                    if(accountButton !== null && accountButton.contains(event.target as Node)) {
                        if(modal.classList.contains("invisible") || !modal.classList.contains("visible")) {
                            modal.classList.remove("invisible")
                            modal.classList.add("visible")
                            // trigger fade in animation
                           // modal.classList.add("animate-[fade-in_0.5s_ease-in-out]")
                            // animaiton top to bottom
                            modal.classList.add("animate-[fade-in_0.3s_ease-in-out]")
                            setTimeout(() => {
                                modal.classList.remove("animate-[fade-in_0.3s_ease-in-out]")
                            },300)
                            return;
                        }
                    }

                    if(modal.classList.contains("visible") || !modal.classList.contains("invisible")) {
                        modal.classList.add("animate-[fade-out_0.3s_ease-in-out]")
                        setTimeout(() => {
                            console.log("is visible")
                            modal.classList.remove("visible")
                            modal.classList.add("invisible")
                            modal.classList.remove("animate-[fade-out_0.3s_ease-in-out]")
                        },300)

                        // trigger fade out animation

                    }
                }
            })
        };

        // Check if the page has already loaded
        if (!initialized.current) {
            initialized.current = true
            onPageLoad()
        }

    })
    const dashboardLink = "/dashboard/" + props.id
    const settingsLink =  dashboardLink + "/settings"
    return (
        <>
            <nav style={{
                backdropFilter: "blur(20px)",
                WebkitBackdropFilter: "blur(20px)",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                maxWidth: "88%",
            }}
                className={'bg-white dark:bg-black drop-shadow-sm rounded-b-3xl border-b sm:mx-auto max-sm:ml-auto select-none '}>
                <div className={'ml-8 xl:ml-32 md:block md:w-auto'}>
                    <div className="flex flex-wrap items-center mx-auto">

                        <div className={"flex items-center space-x-3 rtl:space-x-reverse"}>
                            <Link to="/">
                                <img src="/new_cloud_black_mini.png" className="h-8" alt="DreamNetwork☁️"/>
                            </Link>
                            {props.showProjectIcon &&
                                <>
                                    <p className={"text-2xl"}>»</p>
                                    <img src={projectIcon}
                                         className={"h-8 rounded-3xl animate-[slide-in-left_1s_ease-in-out]"}
                                         alt="project icon"/><p
                                    className={"flex ml-6 text-lg animate-[slide-in-left_1s_ease-in-out]"}>{props.projectName}</p></>
                            }
                            {!props.showProjectIcon &&
                                <div className={"flex pl-32 text-lg"}></div>
                            }
                        </div>


                        {props.isInProject &&
                            <ul className={"max-sm:absolute sm:ml-7 md:ml-36 lg:ml-64 xl:ml-96 max-sm:invisible font-medium flex p-4 md:p-0 mt-1 gap-14 sm:flex-row sm:space-x-8 rtl:space-x-reverse md:mt-0"}>
                                <li className={"text-pink-800 hover:text-pink-900 max-sm:invisible"}>
                                    <Link to={dashboardLink}>Dashboard</Link>
                                    {props.selected === 0 && <div
                                        className={"w-20 mt-3 absolute  h-1 overflow-hidden bg-pink-800 animate-[browse-in_0.5s] rounded-full"}/>}
                                </li>

                                <li className={"text-pink-800 hover:text-pink-900 max-sm:invisible"}>
                                    <Link onClick={() => {
                                        props.onSettingsClick()
                                    }} to={settingsLink}>Settings</Link>
                                    {props.selected === 1 && <div
                                        className={"w-14 mt-3 absolute h-1 bg-pink-800 animate-[browse-in_0.5s] rounded-full mr-3"}/>}
                                </li>
                            </ul>
                        }
                        <div className={" ml-auto mr-6 hover:cursor-pointer"} id={"accountButton"} onClick={() => {
                            // remove invisible class on modal
                        }}>
                            <img src={"https://www.gravatar.com/avatar/" + sha256Mail + "?d=mp"}
                                 className={"rounded-full h-10 w-10 border-2 border-b-purple-950"} alt="profile"/>

                        </div>

                    </div>
                </div>

            </nav>
            <div
                className={"absolute invisible w-40 h-44 rounded-xl z-50 overflow-hidden  drop-shadow-lg"}
                style={{
                    // get the position of the button and set the position of the modal
                    top: props.isInProject ? "4rem" : "3rem",
                    left: accountLeft,
                }}
                id={"accountModal"}>

                <div className="w-0 h-0 border-l-[15px] border-l-transparent border-b-[10px] border-b-white border-r-[10px] border-r-transparent ml-11"/>
                <div className={"bg-white dark:bg-black w-full h-full rounded-xl p-4 relative z-50 select-none"}>

                    <p className={"text-center font-light underline my-2"}>{user.nickname}</p>
                    <li className={"text-pink-800 hover:text-pink-900 z-50 list-none p-3 bg-neutral-50 rounded-lg hover:bg-neutral-200 hover:cursor-pointer  transition-colors duration-300" } onClick={() => {
                        if(window.location.pathname === "/account") {
                            return;
                        }

                        navigate("/account")

                    }}>
                        <p>Settings ⚙️</p>
                    </li>
                    <li className={"text-red-800 hover:text-pink-900 list-none mt-3 p-1 bg-danger-50 rounded-lg hover:bg-danger-300 hover:cursor-pointer transition-colors duration-300"} onClick={() => {
                        localStorage.removeItem("jwt")
                        localStorage.removeItem("userData")
                        window.location.href = "/"
                    }}>
                        <p>→ Logout 🚪</p>
                    </li>
                </div>

            </div>
        </>

    );
}

export default Navbar;