/* eslint-disable no-shadow */
import React, {PureComponent} from 'react';
import {PieChart, Pie, Cell, Label} from 'recharts';

const RADIAN = Math.PI / 180;

const cx = 100;
const cy = 100;
const iR = 60;
const oR = 75;
//const value = 50;

// eslint-disable-next-line no-unused-vars
const needle = (value: number, data: any[], cx: number, cy: number, iR: number, oR: number, color: string | undefined) => {
    let total = 0;
    data.forEach((v) => {
        total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle  style={{outline: 'none'}} cx={x0} cy={y0} r={r} fill={color} stroke="none"/>,
        <path   style={{outline: 'none'}} d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color}/>,
    ];
};
const CustomLabel = ({viewBox, labelText ,subText, value}: any) => {
    const {cx, cy} = viewBox;
    return (
        <g  style={{outline: 'none', userSelect: 'none'}}>
            <text
                x={cx}
                y={cy-20}
                className="recharts-text recharts-label"
                textAnchor="middle"
                dominantBaseline="central"
                alignmentBaseline="middle"
                fontSize="15"
                style={{outline: 'none'}}
            >
                {labelText}
            </text>
            <text
                x={cx}
                y={cy + 5}
                className="recharts-text recharts-label"
                textAnchor="middle"
                dominantBaseline="central"
                alignmentBaseline="middle"
                fill="#0088FE"
                fontSize="26"
                fontWeight="600"
                style={{outline: 'none'}}
            >
                {value}
            </text>
            <text
                x={cx}
                y={cy+30}
                className="recharts-text recharts-label"
                textAnchor="middle"
                dominantBaseline="central"
                alignmentBaseline="middle"
                fontSize="15"
                style={{outline: 'none'}}>
                {subText}

            </text>

        </g>
    );
};

export default class CircleChart extends PureComponent {
    props = {
        value: 0,
        name: "CPU",
        textRendering: "N/A",
        subText: ""
    }


    constructor(props: any) {
        super(props);
    }

    render() {
        if(this.props.value === null){
            this.props.value = 0;
        }
        console.log("render with "+ this.props.value)
        const data = [
            this.props.value <= 100 && this.props.value > 75 ? {
                    name: 'A', value: this.props.value, color: '#c63939'
                } :
                this.props.value <= 75 && this.props.value > 50 ? {
                        name: 'A', value: this.props.value, color: '#EFAD2A'
                    } :
                    this.props.value <= 50 && this.props.value > 25 ? {
                            name: 'A', value: this.props.value, color: '#80c639'
                        } :
                        {name: 'A', value: this.props.value, color: '#39c680'},
            {name: 'B', value: 100 - this.props.value, color: '#ffffff'},
        ]
        return (
            <div className={"wow"} style={{
                maxWidth: "210px",
                maxHeight: "180px",
            }}>
                <PieChart  style={{outline: 'none', userSelect: 'none'}} width={210} height={180} className={"select-none"}>
                    <Pie className={"select-none"}  style={{outline: 'none'}}
                         dataKey="value"
                         startAngle={230}
                         endAngle={-50}
                         data={data}
                         cx={cx}
                         cy={cy}
                         innerRadius={iR}
                         outerRadius={oR}
                         fill="#8884d8"
                         stroke="none"
                    >
                        {data.map((entry, index) => (
                            <Cell style={{outline: 'none'}} className={"select-none"} key={`cell-${index}`}  fill={entry.color}/>
                        ))}
                        <Label  style={{outline: 'none', outlineWidth: '0px'
                        }}
                                content={<CustomLabel  style={{outline: 'none', outlineWidth:'0px' , outlineColor: 'black'}} labelText={this.props.name} value={this.props.textRendering} subText={this.props.subText}/>}
                                position="center"
                        />
                    </Pie>
                </PieChart>
            </div>

        );
    }

}
