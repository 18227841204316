import { PureComponent} from "react";





type Props = {
    style?: React.CSSProperties; // Add this line to include the style prop
    moveMultiplier: number;
    backgroundCircle: boolean;
    maxWidth?: number;
    maxHeight?: number;
    className?: string;
    // other props...
};
export class CardHover extends PureComponent<Props> {

    static id = 0;
    currentID = CardHover.id++;
    mouseDown = false;
    props: any;

    constructor(props: any) {
        super(props);
        this.props = props;
        this.state = {
            hover: false
        }
    }

    render() {
        return (
            <div id={"cardBg-"+this.currentID} style={{
                backgroundColor: "rgba(243, 244, 246, 0.8)",
                borderRadius: "16px",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",

                // inline-block
                display: "inline-block",
            }} onLoadStart={() => {
                console.log("onload")
                const card = document.getElementById("cardBg-"+this.currentID) as HTMLElement;
                if(this.props.maxWidth !== undefined)
                    card.style.maxWidth = this.props.maxWidth + "px";
                if(this.props.maxHeight !== undefined)
                    card.style.maxHeight = this.props.maxHeight + "px";

                if(this.props.style !== undefined) {
                    const cssProp = this.props.style as React.CSSProperties;
                    // merge all style
                    card.style.cssText = Object.entries(cssProp).map(([key, value]) => `${key}: ${value}`).join(';');
                }

                if(this.props.className !== undefined) {
                    console.log("new class name")
                    card.className += " "+ this.props.className;
                }
            }} onMouseDown={
                (event) => {
                    this.mouseDown = true;
                    // bounce
                    const card = event.currentTarget as HTMLElement;
                    card.style.transform = "scale(0.95)";
                    card.style.transition = "all 0.2s ease-in-out";

                }
            } onMouseUp={
                (event) => {

                    // bounce
                    const card = event.currentTarget as HTMLElement;
                    card.style.transform = "scale(1.05)";
                    card.style.transition = "all 0.15s ease-in-out";
                    setTimeout(() => {
                        card.style.transition = "all 0.1s ease-in-out";
                        card.style.transform = "scale(1)";
                    }, 100)
                    setTimeout(() => {
                        this.mouseDown = false;
                    },300)

                }
            } onMouseMove={(event) => {

                //3D effect
                const card = event.currentTarget as HTMLElement;

                setTimeout(() => {
                    card.style.removeProperty("transition");
                }, 100)
                const mouseX = event.clientX;
                const mouseY = event.clientY;

                // move transform 3D effect
                // get center of card
                const rect = card.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;

                // get multplier with size of card
                const multiplierX =rect.width / 25;
                const multiplierY = rect.height / 25;
                const multiplierZ = 500;

                // get angle
                const angleX = ((centerY - mouseY) / multiplierX)*this.props.moveMultiplier
                const angleY = ((centerX - mouseX) / multiplierY)*this.props.moveMultiplier
                const angleZ = ((centerX - mouseX) / multiplierZ)*this.props.moveMultiplier
                const blendMax = 30*this.props.moveMultiplier;
               // const blendMin = -30;

                // get pourcent of y
                let pourcentCenterY = ((centerY - mouseY) / rect.height)*2;
                if(pourcentCenterY > 1) pourcentCenterY = 1;
                if(pourcentCenterY < -1) pourcentCenterY = -1;

                // get pourcent of x
                let pourcentCenterX = ((centerX - mouseX) / rect.width)*2;
                if(pourcentCenterX > 1) pourcentCenterX = 1;
                if(pourcentCenterX < -1) pourcentCenterX = -1;


                const blendY = pourcentCenterY * blendMax;
               // const blendX = pourcentCenterX * blendMax;

                //const totalBlend = blendY + blendX;




                // get total angle to blend
             //   const angle = angleX + angleY;

                //card.style.transform = "rotateX(" + angleX + "deg) rotateY(" + angleY + "deg) rotateZ(" + angleZ + "deg)";
                // 3D
                if(!this.mouseDown) {
                    card.style.transform = "scale(1.03) rotate3d(" + angleX + ", " + angleY + ", " + angleZ + ", " + blendY + "deg)";
                    card.style.transformStyle = "preserve-3d";
                }


                // mouse position effect color where the mouse is
                if(this.props.backgroundCircle)
                    card.style.background = "radial-gradient(circle at "+(mouseX-rect.left)+"px "+(mouseY-rect.top)+"px, rgba(247,205,246,1) 0%, rgba(252,242,252,1) 51%, rgba(217,217,217,1) 97%) 13% 0% no-repeat padding-box";

            }} onMouseEnter={() =>{
                this.setState({hover: true})
                //3D effect
                const card = document.getElementsByClassName("card-"+this.currentID) as HTMLCollectionOf<HTMLElement>;
                for (let i = 0; i < card.length; i++) {
                    card[i].style.removeProperty("transition");
                    if(this.props.backgroundCircle)
                        card[i].style.removeProperty("backgroundColor");

                    // zoom
                   // card[i].style.transform = "scale(1.01)";
                    card[i].style.transition = "all 0.1s ease-in-out";
                    //drop shadow
                    card[i].style.boxShadow = "0 6px 30px rgba(0, 0, 0, 0.2)";

                    setTimeout(() => {
                        card[i].style.removeProperty("transition");
                    })


                   /* card[i].style.transform = "rotate3d(0, 1,0.1, 10deg)";
                    card[i].style.transition = "all 0.5s ease-in-out";
                    card[i].style.backfaceVisibility = "hidden";

                    card[i].style.transformStyle = "preserve-3d";
                    card[i].style.transformOrigin = "center";*/
                }

            }} onMouseLeave={() => {
                this.setState({hover: false})
                 const card = document.getElementsByClassName("card-"+this.currentID) as HTMLCollectionOf<HTMLElement>;
             for (let i = 0; i < card.length; i++) {
                    card[i].style.removeProperty("background")
                    card[i].style.backgroundColor = "rgba(243, 244, 246, 0.8)";
                   card[i].style.transform = "rotate3d(0, 1,0.1, 0deg)";
                   card[i].style.transition = "transform 0.5s ease-in-out";
                   card[i].style.backfaceVisibility = "hidden";
                   card[i].style.boxShadow = "0 4px 20px rgba(0, 0, 0, 0.1)";

                   card[i].style.transformStyle = "preserve-3d";

                   card[i].style.transformOrigin = "center";


               }
            }}  className={"card-"+this.currentID +" "+ (this.props.className !== undefined ? " "+ this.props.className : "")}>
                {this.props.children}
            </div>

        );

    }

    handleHover() {
        this.setState({hover: true})
        //3D effect
        const card = document.getElementsByClassName("card-"+this.currentID ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < card.length; i++) {
            card[i].style.transform = "rotate3d(0, 1,0.1, 10deg)";
            card[i].style.transition = "all 0.5s ease-in-out";
            card[i].style.backfaceVisibility = "hidden";

            card[i].style.transformStyle = "preserve-3d";
            card[i].style.transformOrigin = "center";
        }
    }
}


