import Message from "./Message";
import SessionManager from "./SessionManager";
import Session from "./Session";


export class SessionListener{
    sessions = new Map<string, SessionInterface>();
    currentFrame: string | undefined;
    frameListeners = new Map<string, Array<SessionInterface>>();

    public getByChannel(channel: string): SessionInterface | undefined{
        return this.sessions.get(channel);
    }

    public getCurrentFrame(): Array<SessionInterface> | undefined{
        if(this.currentFrame == undefined){
            return undefined;
        }
        return this.frameListeners.get(this.currentFrame);

    }

    public getByFrame(frame: string): Array<SessionInterface> | undefined{
        return this.frameListeners.get(frame);
    }
    addChannelListener(session: SessionInterface, channel: string){
        this.sessions.set(channel, session);
    }

    exitCurrentFrame(){
        if(this.currentFrame != undefined){
            const oldFrame = this.currentFrame;
            for (let i = 0; i < SessionManager.sessions.length; i++) {
                const session = SessionManager.sessions[i];
                session.send(new Message().set("frame", this.currentFrame).set("type", "leave"));
            }
            this.removeFrameListener(oldFrame);
            this.currentFrame = undefined;
        }
    }


    removeChannelListener(id: string){
        this.sessions.delete(id);
    }

    getChannelsListener(id: string): SessionInterface | undefined{
        return this.sessions.get(id);
    }


    getChannelsListeners(): SessionInterface[]{
        const array = new Array<SessionInterface>();
        //eslint-disable-next-line
        this.sessions.forEach((value, key) => {
            array.push(value);
        });
        return array;
    }

    addFrameListener(session: SessionInterface, frame: string){
        if(!this.frameListeners.has(frame)){
            this.frameListeners.set(frame, new Array<SessionInterface>());
        }
        const array = this.frameListeners.get(frame);
        if(array != undefined){
            array.push(session);
        }
    }

    removeFrameListener(id: string){
        this.frameListeners.delete(id);
    }

    newFrame(page : string, sessionInterface : SessionInterface){
        const oldFrame = this.currentFrame;
        if(oldFrame == page){
            return;
        }
        if(oldFrame != undefined){
            this.removeFrameListener(oldFrame);
        }
        this.currentFrame = page;
        this.addFrameListener(sessionInterface, page);
        for (let i = 0; i < SessionManager.sessions.length; i++) {
            const session = SessionManager.sessions[i];
            if(oldFrame != undefined){
                session.send(new Message().set("frame", oldFrame).set("type", "leave"));
            }
            session.send(new Message().set("frame", page).set("type", "enter"));
        }
        sessionInterface.onEnter?.();
    }
}

export interface SessionInterface{
    //eslint-disable-next-line
    onRead(message: Message,session: Session): void;
    //eslint-disable-next-line
    onEnter?(): void;
}