export class WindowEventListener {
    private static eventListenersMap: Map<string, EventListenerOrEventListenerObject> = new Map();

    public static registerEventListener(name: string,event: string, callback: EventListenerOrEventListenerObject) {
        console.log("Registering event listener for " + name);
        if(this.eventListenersMap.has(name)){

            this.removeEventListener(name,event);
            console.log("Removed event listener for " + name);
        }
        this.eventListenersMap.set(name, callback);
        window.addEventListener(event, callback);
    }

    public static removeEventListener(name: string, event: string) {
        const callback = this.eventListenersMap.get(name);
        if (callback) {
            window.removeEventListener(event, callback);
        }
    }

    public static forceCall(name: string, event: string){
        const callback = this.eventListenersMap.get(name) as EventListener;
        if (callback) {
            callback.call(window, new Event(event));
        }
    }
}