export class IdSet{
    ids = new Array<number>();


    public getIds(): number[]{
        return this.ids;
    }

    public findAvailableId(): number{
        let id = 0;
        // eslint-disable-next-line
        while(true){
            if(!this.ids.includes(id)){
                return id;
            }
            id++;
        }
    }

    public addId(id: number){
        this.ids.push(id);
    }

    public removeId(id: number){
        const index = this.ids.indexOf(id);
        if(index > -1){
            this.ids.splice(index, 1);
        }
    }
}