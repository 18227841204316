import React, {useEffect, useState} from "react";
import SessionManager from "../../../../WebSocket/SessionManager";
import {SessionInterface} from "../../../../WebSocket/SessionListener";
import Message from "../../../../WebSocket/Message";
import Session from "../../../../WebSocket/Session";
import {FaCircle, FaGear, FaPeopleGroup, FaTag} from "react-icons/fa6";
import {FaBolt} from "react-icons/fa";
import {IServiceProps} from "../Services";
import {ServicesUtils} from "../../../Utils/ServicesUtils";

export type Executor = {
    bundle: string,
    name: string,
    type: string,
    instances: number,
    players: number,
    xmx: number,
    xms: number,
    startup: number,
    customName: string,
    port: number,
    jvmType: string
    profiles?: Array<string>
}
export default function Executors(props: IServiceProps){
    const defaultMap = new Map<string, Array<Executor>>();
    /*defaultMap.set("bundle1", [
        {bundle: "test", name: "youpi", type: "DYNAMIC", instances: 2, xmx: 2, xms: 2, startup: 2, customName: "test", port: 3, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 0, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "server", type: "STATIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "proxy", type: "DYNAMIC", instances: 0, xmx: 1, xms: 1, startup: 0, customName: "test", port: 1, jvmType: "proxy",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "proxy",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 0, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
        {bundle: "test", name: "krado", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test", port: 1, jvmType: "server",players: 2},
    ]);

    defaultMap.set("bundle2", [{bundle: "test2", name: "test2", type: "STATIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test2", port: 1, jvmType: "server" ,players: 2}]);
    defaultMap.set("bundle3", [{bundle: "test2", name: "test2", type: "DYNAMIC", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test2", port: 1, jvmType: "server" ,players: 2}]);
   // defaultMap.set("test3", [{bundle: "test3", name: "test3", type: "test3", instances: 1, xmx: 1, xms: 1, startup: 1, customName: "test3", port: 1, jvmType: "test3"}]);*/
    const [executors, setExecutors] = useState(new Map<string, Array<Executor>>(defaultMap));


    const executorDiv = (executor: { bundle: string; name: string; type: string; instances: number; xmx: number; xms: number; startup: number; customName?: string; port: number; jvmType: any; players: number }) => {
        const square = "square-"+executor.name;
        return (
            <div className={"bg-white  pl-4 pr-28 pt-2 pb-20 rounded-3xl hover:scale-105 transition-all duration-500 hover:cursor-pointer hover:brightness-110"} onClick={
                () => {
                    props.setSelectedExecutor(executor);
                }
            }>
            <div className={" rounded-xl w-16 "}>

                <div id={square} className={" rounded-2xl px-20 py-20  flex"}  style={{
                    background: "linear-gradient(180deg, rgba(195,172,255,1) 0%, rgba(255,171,229,1) 62%, rgba(242,194,194,1) 96%)"
                }}>


                </div>
                <div className={"absolute z-50 hover:scale-110 hover:brightness-110"} style={{
                    marginTop: "-180px",
                    marginLeft: "-13px"
                }}>
                    <img src={"https://static.spigotmc.org/img/spigot-og.png"} className={"w-12 h-12 "} alt={"logo"}/>
                </div>
                <div className={"absolute z-50  rounded-lg pl-1 pb-0.5 hover:scale-110 hover:brightness-110"} style={{
                    marginTop: "-165px",
                    marginLeft: "135px",
                    scale: "1.5",
                    background: "linear-gradient(0deg, rgba(195,172,255,1) 20%, rgba(255,171,229,0.021428639815301076) 70%, rgba(242,194,194,0) 100%)"
                }}>
                    <FaGear className={"flex"}/>
                </div>
                <div className={"absolute drop-shadow-2xl hover:cursor-pointer"}
                     style={{
                    marginTop: "-155px",
                    marginLeft: "5px"
                }}>
                    {executor.jvmType === "PROXY" &&
                        <img src={"https://proxy.spigotmc.org/0e97ad6ff9f812673c4274b355fb5aa8289bc6e9?url=http%3A%2F%2Fi.imgur.com%2FMAg2r2J.png"} className={""} height={150} width={150} onLoadCapture={event => {
                            // center margin
                            const img = event.target as HTMLImageElement;
                            const height = img.height;
                           // const width = img.width;
                            const marginTop = (150 - height) / 2;
                            //const marginLeft = (200 - width) / 2;
                            img.style.marginTop = marginTop + "px";
                            //img.style.marginLeft = marginLeft + "px";

                        }} alt={"proxy"}/>
                    }

                    {
                        executor.jvmType === "SERVER" &&
                        <img src={"https://qph.cf2.quoracdn.net/main-qimg-c2af83c464f142f080fc5ce687e0ead3-pjlq"} className={`rounded-2xl ${executor.instances == 0 ? "grayscale": ""}`} style={{
                            minWidth: "150px",
                            minHeight: "150px",
                            maxHeight: "150px",
                            maxWidth: "150px",
                            objectFit: "cover"
                        }} alt={"server"}/>
                    }
                </div>



                </div>
                {executor.instances >= 1 &&
                    <p className={"text-green-500 absolute text-sm ml-36 mt-1 text-center font-bold"}>
                        <FaCircle className={""}/> {executor.instances}
                        <br/>
                        <span className={"text-gray-800"}>
                             <FaPeopleGroup className={""}/> {executor.players}
                        </span>


                    </p>

                }
                {executor.instances === 0 &&
                    <FaCircle className={"text-red-500 absolute ml-36 mt-1"}/>
                }
                <p className={"w-full absolute"}><FaTag className={"inline"}/>{executor.name}</p>
                <p className={"w-full absolute mt-6"}><FaBolt className={"inline"}/>{executor.type}</p>



            </div>

        )
    }

    useEffect(() => {

        SessionManager.getSessionListener().newFrame("executors", new class implements SessionInterface {
            onRead(message: Message, session: Session) {
                console.log(message);
                console.log(session);
                // my java message  getSession().send(new WebMessage()
                //                     .put("bundle", executor.getBundleData().getName())
                //                     .put("name", executor.getName())
                //                     .put("type", executor.getType().name())
                //                     .put("instances", executor.getServices().size())
                //                     .put("xmx", executor.getXmx())
                //                     .put("xms", executor.getXms())
                //                     .put("startup", executor.getStartup())
                //                     .put("customName", executor.getCustomName())
                //                     .put("port", executor.getPort())
                //                     .put("jvmType",executor.getBundleData().getJvmType()));
                //
                //
               // if(!message.has("bundle")) return;
               const executor = ServicesUtils.composeExecutorsWithMessage(message);
               if(executor == undefined) return;

                    if(executors.has(executor.bundle)){
                        const array = executors.get(executor.bundle);
                        if(array == null) return;
                        array.push(executor);
                        executors.set(executor.bundle, array);
                        setExecutors(() => {
                            return new Map(executors);
                        })
                        return;
                    }

                    executors.set(executor.bundle, [executor]);
                    setExecutors(() => {
                        return new Map(executors);
                    })
                    return;
            }
        });
    }, []);
    return(

        <div className={"flex flex-col"}>
            {Array.from(executors.keys()).map((key) => {
                const array = executors.get(key);
                if(array == null) return;

                return (
                    <div>
                        <p className={"font-bold text-2xl mt-4"}> {key} </p>
                        <div className={"w-1/2 pb-0.5 bg-neutral-400 mb-4"}/>
                        <div className={"flex flex-wrap rounded-xl  w-full gap-2"}>
                            {array.map((executor) => {
                                    return (
                                        <div className={"flex flex-wrap rounded-xl w-16 mr-24 ml-10 "}>
                                            <p className={"text-center font-bold ml-16 underline"}>{executor.players}</p>
                                            {executorDiv(executor)}
                                        </div>
                                    )
                            })}
                        </div>
                    </div>
                )

            })}
        </div>
    )

}