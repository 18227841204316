export class WidthManager{
    static left = 300;
    static right = 250;
    static currentTotalWidth = window.innerWidth;




    static refreshWidth(){
        this.currentTotalWidth = window.innerWidth;
    }
    static getLeftWidth(): number{
        if(this.currentTotalWidth <= 640){
            return 0;
        }
        return this.left;
    }

    static minimizeBar(): void{
        this.left = 80;
    }

    static maximizeBar(): void{
        this.left = 300;
    }


    static getAvailableWidth(isFull: boolean): number{
        //check if sm

        if(isFull){
            console.log((this.currentTotalWidth - this.getLeftWidth()));
            return (this.currentTotalWidth - this.getLeftWidth())
        }
        return (this.currentTotalWidth - this.getLeftWidth() - this.right);
    }



    static getRightWidth(): number{
        return this.right;
    }
}