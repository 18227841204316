import React, {useEffect, useState} from "react";
import {WidthManager} from "./WidthManager";
import {
    FaEarthEurope,
    FaGamepad,
    FaPeoplePulling,
    FaServer,
    FaCloud,
    FaFolder,
    FaBookBookmark,
    FaDiscord,
    FaPadlet
} from "react-icons/fa6";
import {FaAlignJustify} from "react-icons/fa";
import {WindowEventListener} from "../../Utils/WindowEventListener";
import {Link} from "react-router-dom";



interface LeftBarProps {
    current: number
    projectIcon?: string
    projectName?: string
    setCurrent?: any
    projectID?: string

}



const LeftBar: React.FunctionComponent<LeftBarProps> = (props) => {
    const [barSize, setBarSize] = useState(WidthManager.getLeftWidth())
    const [isMinimized, setIsMinimized] = useState(false)
    const [isOnMobile, setIsOnMobile] = useState(false)
    const dashboardLink = "/dashboard/" + props.projectID;
    const settingsLink =  dashboardLink + "/settings"

    useEffect(
        () => {
            setIsOnMobile(window.innerWidth <= 640)
            setBarSize(WidthManager.getLeftWidth())
            WindowEventListener.registerEventListener("resizeLeftBar","resize", () => {
                WidthManager.refreshWidth()

                setIsOnMobile(window.innerWidth <= 640)
                if(isMinimized && isOnMobile) {
                    WidthManager.maximizeBar()
                    setBarSize(WidthManager.getLeftWidth())
                    setIsMinimized(false)
                }
            });
        }
    )

    const generateListItem = (index: number, emoji: any, text: string, current: number, setCurrent: any) => {
        const isActive = index === current;
        let additionalClasses: string;
        if(!isMinimized){
           additionalClasses = isActive
                ? "bg-purple-500 text-white dark:bg-purple-500 dark:text-white ml-5 w-10/12 sm:transition-all sm:duration-500"
                : "hover:bg-neutral-200 dark:hover:bg-gray-900";
        }else {
            additionalClasses = isActive
                ? "bg-purple-500 text-white dark:bg-purple-500 dark:text-white ml-1 w-10/12 sm:transition-all sm:duration-500"
                : "hover:bg-neutral-200 dark:hover:bg-gray-900";
        }

        const additionalStyle = isActive
            ? {filter: "drop-shadow(0px 3px 0.1rem #563075)",
                transform: "scale(1.01)",
                background: "rgb(38,35,88) linear-gradient(90deg, rgba(125,77,164,1) 0%, rgba(175,48,185,1) 59%, rgba(164,84,177,1) 100%)"}
            : {
                width: "calc(100%)",
                transform: "scale(1)",
            };

        return (
            <li
                key={index}
                className={`py-3 my-0.5 cursor-pointer w-11/12 rounded-tr-md rounded   ${additionalClasses}`} style={additionalStyle}
                onClick={() => {
                    if (index !== -1) {

                        setCurrent(index)


                        if(isOnMobile){
                            const leftBar = document.getElementById("leftBar")
                            if(leftBar?.classList.contains("max-sm:invisible")) {
                                leftBar?.classList.remove("max-sm:invisible")
                                leftBar?.classList.add("max-sm:visible")
                            }else {
                                leftBar?.classList.remove("max-sm:visible")
                                leftBar?.classList.add("max-sm:invisible")
                            }
                        }

                    }
                }}
            >
                <div className="">
                    <div className="overflow-hidden">
                        {!isMinimized && (
                            <p className={`text-xl ml-5 flex flex-row`}><span className={`mt-1 mr-2 ${isActive ? "text-purple-100" : ""}`}>{emoji}</span> {text}</p>
                    )}
                    {isMinimized && (
                        <p className={`text-xl ml-6 ${isActive ? "text-white" : ""}`}>{emoji}</p>
                    )}

                </div>
            </div>
    </li>
    );
    };
    let projectIcon: string | undefined = "/cube.png"
    if(props.projectIcon !== null) {
        projectIcon = props.projectIcon
    }
    const lineClass = isMinimized ?
        "my-2 p-0.5 bg-neutral-400" : "my-2 p-0.5 mx-8 bg-neutral-300";

    return (
        <div className="rounded-3xl sm:w-2/6 md:w-3/12 lg:w-3/12 xl:w-1/6 ml-2 select-none" style={
            {
                minWidth: barSize,
                maxWidth: barSize,
            }
        }>
            {/* Autres éléments de la barre latérale ici */}

            {/* Div centrée verticalement et réduite de 1/2 */}

            <div id={"leftBar"} className="h-[90vh] sm:mt-3 rounded-xl border-gray-50 drop-shadow-lg overflow-y-auto bg-white dark:bg-black max-sm:z-50 max-sm:absolute max-sm:invisible" style={
                {
                    borderRadius: "16px",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(0px)",
                    WebkitBackdropFilter: "blur(5px)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    minWidth: "100%",
                    maxWidth: "100%",
                    zIndex: 50,
                }
            }>



                <div className={"max-sm:absolute sticky max-sm:w-full"}>
                    { isOnMobile &&
                        <div className={"my-4 flex flex-col text-xl"}>

                            <p  className={"mb-3 ml-5 text-pink-800 hover:text-pink-900 ml-5"}>
                                Settings
                            </p>

                                <Link to={settingsLink}>
                                    {generateListItem(-1, <FaPadlet />,"Settings", props.current, props.setCurrent)}
                                </Link>

                            <div className={"p-0.5 mr-3 ml-2 bg-purple-200 "}/>

                        </div>}

                    <div className=" items-center px-5 pt-3 ">

                        <div className={`flex flex-row hover:bg-purple-50 hover:cursor-pointer rounded-xl py-1 ${!isMinimized ? " max-w-full" : ""}`} onClick={() => {
                            if(isOnMobile) {
                                return;
                            }

                            if(isMinimized) {
                                WidthManager.maximizeBar()
                            }else {
                                WidthManager.minimizeBar()

                            }
                            setBarSize(WidthManager.getLeftWidth())
                            setIsMinimized(!isMinimized)
                            console.log("youhou")
                            WindowEventListener.forceCall("centerResize","resize")
                            WindowEventListener.forceCall("centerResize","resize")

                        }}>
                           <img src={projectIcon} className={"h-10 rounded-xl"}/>


                            {!isMinimized && <p className={"text-2xl text-center ml-5"}>{props.projectName}</p>  }
                        </div>

                    </div>
                    <div className={isMinimized ?
                        "my-2 p-0.5 bg-purple-200" : "my-2 p-0.5 mr-3 ml-2 bg-purple-200 "}/>
                    <ul className="my-4 flex flex-col ">
                        {generateListItem(0, <FaCloud />,"Overview", props.current, props.setCurrent)}

                        <div className={lineClass} style={{marginBottom: "4px", marginTop: "8px"}} />
                        {generateListItem(1, <FaEarthEurope />,"Nodes", props.current, props.setCurrent)}
                        {generateListItem(2, <FaServer/>,"️Services", props.current, props.setCurrent)}
                        {generateListItem(3, <FaPeoplePulling />,"Players", props.current, props.setCurrent)}

                        <div className={lineClass} />
                        {generateListItem(-1, <FaFolder />,"File manager", props.current, props.setCurrent)}
                        {generateListItem(-1, <FaGamepad />,"️Addons", props.current, props.setCurrent)}

                        <div className={lineClass} />
                        {generateListItem(-1,<FaDiscord /> , "️Support", props.current, props.setCurrent)}
                        {generateListItem(-1, <FaBookBookmark />,"Documentation", props.current, props.setCurrent)}
                    </ul>


                    <div className={"mt-5 ml-3 cursor-pointer"}>
                        <p> ← Go home 🏠</p>
                    </div>
                </div>

            </div>
            <div className={"my-4"}></div>
            <div className={"absolute bg-purple-300 p-1 rounded-xl sm:invisible transition-none"} style={{
                top: "5px",
            }}>
                {/* create a button to toggle the sidebar */}
                <button
                    className="focus:outline-none"
                    onClick={() => {
                        const leftBar = document.getElementById("leftBar")
                        if(leftBar?.classList.contains("max-sm:invisible")) {
                            leftBar?.classList.remove("max-sm:invisible")
                            leftBar?.classList.add("max-sm:visible")
                        }else {
                            leftBar?.classList.remove("max-sm:visible")
                            leftBar?.classList.add("max-sm:invisible")
                        }
                    }}
                >
                    <FaAlignJustify className={"w-8 h-6"} style={{
                        color: "darkblue",
                        marginTop: "5px",
                    }}/>
                </button>

            </div>
        </div>


    );
}

export default LeftBar;