
import React from "react";
import {
    //LineChart,
   // Line,
    XAxis,
    YAxis,
    //CartesianGrid,
    Tooltip,
    Legend, AreaChart, Area
} from "recharts";


export enum NetworkChartType {
    // eslint-disable-next-line no-unused-vars
    SECONDS,
    // eslint-disable-next-line no-unused-vars
    MINUTES
}

//eslint-disable-next-line no-unused-vars
function generateRandomSeconds(seconds : number) : {name: string, uv: number, pv: number, amt: number}[] {
    const data = [] as {name: string, uv: number, pv: number, amt: number}[]
    for (let i = 0; i < seconds; i++) {
        data.push({
            name: seconds-i+"s",
            uv: Math.random() * 100,
            pv: Math.random() * 100,
            amt: Math.random() * 100
        })
    }
    return data
}

// eslint-disable-next-line no-unused-vars
function toFixedNumber({num, digits, base}: { num: any, digits: any, base: any }){
    const pow = Math.pow(base ?? 10, digits);
    return Math.round(num*pow) / pow;
}
function generate(type: NetworkChartType, listInbound: number[], listOutbound: number[]) : {name: string, inbound: number, outbound: number}[] {
    const data = [] as {name: string, inbound: number, outbound: number}[]
    const length = listInbound.length > listOutbound.length ? listInbound.length : listOutbound.length
    if(type === NetworkChartType.SECONDS) {
        // longer stat

        for (let i = 0; i < length; i++) {
            data.push({
                name: listInbound.length-i+"s",
                inbound: listInbound[i] == undefined ? 0 : toFixedNumber({num: listInbound[i]/1024, digits: 2, base: 10}),
                outbound: listOutbound[i] == undefined ? 0 : toFixedNumber({num: listOutbound[i]/1024, digits: 2, base: 10})
            })
        }
    } else {
        for (let i = 0; i < length; i++) {
            data.push({
                name: listInbound.length-i+"m",
                inbound: listInbound[i] == undefined ? 0 : toFixedNumber({num: listInbound[i]/1024, digits: 2, base: 10}),
                outbound: listOutbound[i] == undefined ? 0 : toFixedNumber({num: listOutbound[i]/1024, digits: 2, base: 10})
            })
        }
    }
    return data
}
// eslint-disable-next-line no-unused-vars
const data = [
    {
        name: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100
    }
];

interface INetworkChartProps {
    type: NetworkChartType
    listInbound: number[]
    listOutbound: number[]
}
export default function NetworkChart({type, listInbound, listOutbound}: INetworkChartProps) {
    return (
        <div style={
            {
                //backgroundColor: "rgba(255, 255, 255, 0.65)",
            }
        } className={"m-2 pt-5 rounded-2xl transition-all duration-300 "}>
            <div className={"inline-block"}>
                <AreaChart className={" dark:bg-black rounded-2xl"}
                           width={650}
                           height={300}
                           data={generate(type, listInbound, listOutbound)}
                           margin={{
                               top: 10,
                               right: 30,
                               left: 20,
                               bottom: 5
                           }}

                >
                    {/*<CartesianGrid strokeDasharray="3 3" />*/}
                    <XAxis dataKey="name" />
                    <YAxis unit={"kb"} domain={[0, type == NetworkChartType.SECONDS ? 30:30*60]}/>
                    <Tooltip />
                    <Legend />
                    <Area
                        type="monotone"
                        dataKey="inbound"
                        stroke="#5dcba1"
                        unit={" kilobyte(s)"}
                        fill={"rgba(93,203,161,0.5)"}
                        activeDot={{ r: 6 }}
                    />
                    <Area type="monotone" dataKey="outbound"  unit={" kilobyte(s)"} stroke="#cb5d9d" fill={"rgba(203,93,157,0.5)"} activeDot={{ r: 6 }} />
                </AreaChart>
            </div>


        </div>

    );
}
