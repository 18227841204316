import Message from "../../WebSocket/Message";
import {Service} from "../Pages/Dashboard/Services/ServicesList";
import {Executor} from "../Pages/Dashboard/Services/Executors";
import SessionManager from "../../WebSocket/SessionManager";
import {SessionInterface} from "../../WebSocket/SessionListener";
import Session from "../../WebSocket/Session";

export class ServicesUtils{
    static composeServiceWithMessage(message: Message): undefined | Service{
        const name = message.get("name");
        const players = message.get("players");
        const xmx = message.get("xmx");
        const xms = message.get("xms");
        const startup = message.get("startup");
        const type = message.get("type");

        return {
            name: name,
            players: players,
            xmx: xmx,
            xms: xms,
            startup: startup,
            type: type
        } as Service;
    }

    static composeExecutorsWithMessage(message: Message): undefined | Executor{
        if(!message.has("bundle")) return undefined;
        const bundle = message.get("bundle");
        const name = message.get("name");
        const type = message.get("type");
        const instances = message.get("instances");
        const xmx = message.get("xmx");
        const xms = message.get("xms");
        const startup = message.get("startup");
        const customName = message.get("customName");
        const port = message.get("port");
        const jvmType = message.get("jvmType");
        const players = message.get("players");
        const profiles = message.get("profiles");

        return  {
            bundle: bundle,
            name: name,
            type: type,
            instances: instances,
            xmx: xmx,
            xms: xms,
            startup: startup,
            customName: customName,
            port: port,
            jvmType: jvmType,
            players: players,
            profiles: profiles
        } as Executor;
    }

    static asyncFindService = async (toFind: string | undefined): Promise<{executor: Executor, service: Service}> => {
        return new Promise((resolve, reject) => {
            SessionManager.getSessions().forEach((session) => {
                const handler = new class implements SessionInterface {
                    executor: Executor | undefined
                    service: Service | undefined

                    onRead(message: Message, session: Session) {
                        if (message.get("response") === "find") {
                            if (message.get("rType") === "executor") {
                                this.executor = ServicesUtils.composeExecutorsWithMessage(message)

                                if (this.executor === undefined) {
                                    session.removeHandler(this)
                                    reject("Executor is undefined")
                                    return;
                                }
                                console.log("Composed executor !")
                            }
                            if (message.get("rType") === "service") {
                                this.service = ServicesUtils.composeServiceWithMessage(message)
                                if (this.service === undefined) {
                                    session.removeHandler(this)
                                    reject("Service is undefined")
                                    return;
                                }
                                console.log("Composed service !")
                            }
                            if(message.get("rType") === "notFound"){
                                console.log("Not found")
                                session.removeHandler(this)
                                reject("Not found")
                                return;
                            }
                            if (this.executor !== undefined && this.service !== undefined) {
                                session.removeHandler(this)
                                resolve({executor: this.executor, service: this.service})
                            }
                        }
                    }
                }
                session.addHandler(handler)
                session.send(new Message().set("request", "services").set("find", toFind))
            })
        })
    }
}