import Content from "./Content/Content";
import CenterContent from "./Content/CenterContent";
import {TERipple} from "tw-elements-react";
import SessionManager from "../../../WebSocket/SessionManager";
import {SessionInterface} from "../../../WebSocket/SessionListener";
import Message from "../../../WebSocket/Message";
import Session from "../../../WebSocket/Session";
import {useEffect, useRef, useState} from "react";
import Popup from "../../Utils/Popup";
import {IServiceProps} from "./Services";
import {ServicesUtils} from "../../Utils/ServicesUtils";



type player = {
    name: string,
    time: string,
    id: string
    server?: string
}

interface IPlayersProps {
    setCurrent : any,
    servicesProps: IServiceProps
}
export default function Players(props: IPlayersProps) {
    const list : player[] = []
    const [search, setSearch] = useState({value: ""})
    const [players, setPlayers] = useState<player[]>(list)
    const [waitingFinding, setWaitingFinding] = useState(false)

    const currentPlayerRef = useRef([] as unknown as player);
    const popupRef = useRef<any>(null);

    const searchRef = useRef(search.value);
    //const [totalPlayers, setTotalPlayers] =useState<player[]>(list)
    const totalPlayersRef = useRef(list)

    const setTotalPlayers = (value: player[]) => {
        totalPlayersRef.current = value
    }
    const totalPlayers = totalPlayersRef.current
    const setSearchBar = (value: string) => {
        setSearch((prevSearch) => ({ ...prevSearch, value: value }))
        console.log("set Search bar value "+ search.value)
    }
    useEffect(() => {
        searchRef.current = search.value;
    }, [search.value]);
    const getSearchBar = () => {
        return search.value
    }
    useEffect(() => {
        console.log("It's my fault")
        console.log("Refreshing with search bar")
        refreshWithSearchBar(search.value)
    }, [search.value]);

    useEffect(() => {
        console.log("Players frame created")
        console.log("Adding players frame")
        console.log(getSearchBar())
        SessionManager.getSessionListener().newFrame("players", new class implements SessionInterface {
            onRead(message: Message, session: Session) {
                console.log("Players frame got message ! ")
                console.log("Players => "+ players.length)
                console.log("Search => "+ searchRef.current)
                console.log("Total players => "+ totalPlayers.length)
                console.log(message + "on "+ session.node)
                if(message.has("event")){
                    const event = message.get("event")
                    if(event === "join"){
                        const id = message.get("id")
                        const name = message.get("player")
                        const time = message.get("time") as number
                        let timeString = ""
                        if(time < 1000){
                            timeString = "now"
                        }else {
                            // convert ms to s or m
                            if(time > 60000*60){
                                timeString = ((time/(60000*60))-1).toFixed(0) + "h and " + ((time%(60000*60))/60000).toFixed(0) + "m ago"
                            }else {
                                if(time > 60000){
                                    timeString = (time/60000).toFixed(0) + "m ago"
                                }else
                                    timeString = (time/1000).toFixed(0) + "s ago"
                            }
                        }
                        const server = message.get("server")
                        const player : player = {
                            name: name,
                            time: timeString,
                            id: id,
                            server: server
                        }
                        // push the player to the list with id in order to be able to remove it later
                        // players add the player to the list in order to display it

                        console.log("Player joined ! with name "+ name + " and id "+ id + " and time "+ time + " and server "+ server)

                        // if the player is already in the list, remove it
                        if(players.findIndex(player => player.id === id) !== -1){
                            players.splice(players.findIndex(player => player.id === id), 1)
                        }
                        // if the player has the same id as the current player, hide the popup
                        //console.log("Current player popup id "+ currentPlayerRef.current.id + " and message id "+ id)
                        if(currentPlayerRef.current.id === id) {
                            console.log("Hiding popup")
                            currentPlayerRef.current = player
                        }
                        players.push(player)
                        // sort with id
                        players.sort((a, b) => {
                            return parseInt(a.id) - parseInt(b.id)
                        })
                    }
                    if(event === "quit"){
                        const id = message.get("id")
                        console.log("Current player popup id "+ currentPlayerRef.current.id + " and message id "+ id)
                        if(currentPlayerRef.current.id === id) {
                            console.log("Hiding popup")
                            popupRef.current?.hide()
                        }
                        players.splice(players.findIndex(player => player.id === id), 1)
                    }


                    console.log("New search value "+ searchRef.current)
                    console.log("Updating players list")
                    console.log(players)
                    console.log("Setting players")
                    setTotalPlayers([...players])

                    refreshWithSearchBar(searchRef.current)
                }
            }
        });

    });




    const refreshWithSearchBar = (nSearch: string) => {
        const newPlayers : player[] = []
        console.log("Searching for "+ nSearch)
        for (let i = 0; i < totalPlayers.length; i++) {
            if(nSearch !== "" && !totalPlayers[i].name.startsWith(nSearch)) continue;
            console.log("Adding player "+ totalPlayers[i].name)
            newPlayers.push(totalPlayers[i])
        }
        console.log("Setting players")
        console.log(newPlayers)
        setPlayers(() => [...newPlayers])
    }

    const playerPopup = Popup({children:
            <div className={"flex flex-row "}>

                <div className={"w-24"}>
                    <img src={"https://mc-heads.net/body/" + currentPlayerRef.current.name+"/right"} className={"w-24 rounded-xl"}/>
                </div>
                <div className={"ml-4 flex-col bg-neutral-100 rounded-3xl m-2 p-2 drop-shadow-2xl flex w-1/3"} style={{
                }}>
                    <p className={"font-semibold text-xl"}>Name</p>
                    <p>{currentPlayerRef.current.name}</p>
                    <p className={"font-semibold text-xl"}>Time</p>
                    <p>{currentPlayerRef.current.time}</p>
                    <p className={"font-semibold text-xl"}>ID</p>
                    <p onLoadCapture={event => {
                        console.log(event)
                        console.log(currentPlayerRef.current.server)

                    }}>{currentPlayerRef.current.id}</p>
                    {currentPlayerRef.current.server === undefined ? <></> :
                    <><p className={"font-semibold text-xl"}>Server</p>
                    <p className={"hover:bg-neutral-200 rounded-xl hover:cursor-pointer p-1 hover:underline"} onClick={() => {
                        if(waitingFinding) return;
                        setWaitingFinding(true)
                        //props.setCurrent(2)
                        ServicesUtils.asyncFindService(currentPlayerRef.current.server).then(value => {
                            props.servicesProps.setSelectedExecutor(value.executor)
                            props.servicesProps.setService(value.service)
                            props.setCurrent(2)
                            setWaitingFinding(false)
                        }).catch(reason => {
                            console.log(reason)
                            setWaitingFinding(false)
                        })
                    }}>{currentPlayerRef.current.server}</p></>
                    }
                </div>


            </div>,
        name: "Player - "+ currentPlayerRef.current.name, onClose: () => {}, ref: popupRef})

    function playersToDiv() {
        return (
            <>
                {players.length === 0 ? <div className={"text-center text-2xl font-semibold mt-10"}>No players found</div> : <></>}
                {players.map((player) => playerDiv(player))}
            </>
        );
    }
    function playerDiv(player: player) {
        return (
            <div id={"mySkinContainer"}>
            <li>
                <div className={"m-4 mx-5 w-full h-14 bg-neutral-50 rounded-xl hover:bg-gray-100 transition-all duration-300 hover:scale-[1.01] hover:cursor-pointer"} onClick={() => {
                   // setCurrentPlayerPopup(player)
                    currentPlayerRef.current = player
                    popupRef.current?.show()
                }}>
                    <div className={"flex flex-row"}>
                        <div className={"w-12 h-12 bg-gray-200 rounded-2xl m-1"}>
                            <img src={"https://mc-heads.net/avatar/" + player.name} className={"w-12 h-12 rounded-xl"}/>
                        </div>
                        <div className={"ml-4"}>
                            <div className={"font-semibold text-lg"}>{player.name}</div>
                            <div className={"text-gray-400 ml-1"}>{player.time}</div>
                        </div>
                        <div className={"ml-auto mr-2"}>
                            <div className={"font-semibold text-lg"}>{player.id}</div>
                            <div className={"text-gray-400"}>ID</div>
                        </div>
                    </div>
                </div>
            </li>
            </div>
        )
    }

    return (
        <>
            {playerPopup}
            <Content>
                <CenterContent isFull={true} opacity={0.7}>
                    <div className= "max-sm:ml-6 sm:ml-20 mt-20 ">
                            <div className="mb-10 flex">
                                {players.length !== 0 &&   <p className={"font-light border-b border-gray-800 bg-transparent sm:ml-auto max-sm:w-full bg-clip-padding px-3 py-[0.25rem] text-base text-neutral-900 focus:outline-none outline-green-500 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"}>Players:  {players.length}</p>
                                }

                                <input id={"search"}
                                    type="Search"
                                    className=" border-b border-gray-800 bg-transparent sm:ml-auto max-sm:w-full bg-clip-padding px-3 py-[0.25rem] text-base font-normal text-neutral-900 focus:outline-none outline-green-500 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 "
                                    placeholder="Player Username"
                                    aria-label="Search"
                                    aria-describedby="button-addon2" onInput={
                                    (event) => {
                                        setSearchBar((event.target as HTMLInputElement).value)
                                    }
                                }/>

                                    {/* <!--Search icon--> */}
                                    <TERipple className={"input-group-text flex items-center whitespace-nowrap  px-3 py-1.5  text-base font-normal dark:text-neutral-200 transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer rounded-md"}>
                                        <button>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    className="h-5 w-5">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                                        clipRule="evenodd" />
                                                </svg>
                                            </button>
                                    </TERipple>
                            </div>
                        </div>
                    <div className={"bg-center"}>
                        <div className="w-11/12 h-3">
                             <ul>
                                 {playersToDiv()}
                             </ul>
                        </div>
                    </div>
                    </CenterContent>
                </Content>
            </>
    );
}