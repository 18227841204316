const NotFound = () => {
    return (
        <div className={"flex flex-col justify-center align-center"} style={
            {
                height: "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "rgb(41,31,110) linear-gradient(180deg, rgba(41,31,110,0.8071603641456583) 0%, rgba(87,54,89,0.7707457983193278) 39%, rgba(71,70,78,0.8547794117647058) 100%)"
            }
        }>
            <img src={"/404.png"} className={"absolute w-12/12 bottom-0"}/>

            <h1 className={"text-2xl text-white"}>Wath ?!</h1>
            <p className={"text-white"}>You are lost in a 404 dimension</p>

            <p className={"absolute text-white top-16 left-32 "}>*</p>
            <p className={"absolute text-white top-24 right-64 "}>*</p>
            <p className={"absolute text-white top-8 mr-32 "}>*</p>
            <p className={"absolute text-white top-64 ml-32 "}>*</p>
            <p className={"absolute text-white top-48 mr-96 "}>*</p>
            <p className={"absolute text-white top-48 right-24 "}>*</p>

        </div>
    );
}

export default NotFound;