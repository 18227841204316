import {ConnectData} from "../RestCommunication/Connect";
import Message from "./Message";
import {IdSet} from "./IdSet";
import {SessionInterface} from "./SessionListener";
import SessionManager from "./SessionManager";


export default class Session{
    static idSet: IdSet = new IdSet();
    connectData: ConnectData;
    tryCount: number = 0;
    run: boolean = false;
    currentSocket: WebSocket | null = null;
    handlers: SessionInterface[] = [];
    queue: Message[] = [];
    node: number;
    killed = false;
    constructor(connection: ConnectData) {
        this.connectData = connection;
        this.node = connection.nodeNumber;
    }

    public addHandler(handler: SessionInterface){
        this.handlers.push(handler);
    }

    public removeHandler(handler: SessionInterface){
        this.handlers.splice(this.handlers.indexOf(handler), 1);
    }

    connect() {
        if(!this.run){
            this.run = true;
            this.tryCount++;

            console.log("Connecting")



          // const socket = this.currentSocket = new WebSocket("wss://tunnel.dreamnetwork.cloud/websocket?target_ip=" + this.connectData.remoteIP /*+ ":" + this.connectData.port*/, "Test");
           const socket = this.currentSocket = new WebSocket(this.connectData.connectIP, "Test");
            console.log("current socket : " + this.currentSocket.url)
           //const socket = this.currentSocket = new WebSocket("wss://devnode.dreamnetwork.cloud:2083", "Test");
            //const socket = this.currentSocket = new WebSocket("ws://" + this.connectData.ip + ":" + this.connectData.port, "Test");
            console.log("current socket : " + this.currentSocket)
            console.log("Hey toi ->" +socket.readyState);
            //const socket = new WebSocket("wss://echo.websocket.org")
            // headers
            socket.onopen = () => {
                console.log("Connected")

                this.queue.forEach((message) => {
                    this.send(message);
                });
            }

            socket.onerror = (er) => {
                console.log("Error")
                console.log(er);
            }

            socket.onmessage = (event) => {
                if(event.data == null){
                    return;
                }

                const listeners = SessionManager.getSessionListener();
                console.log("Message received : " + event.data);

                if(Message.canParse(event.data)){
                    console.log("Test with "+ event.data);
                    const message = Message.fromString(event.data);
                    this.handlers.forEach((handler) => {
                        handler.onRead(message, this);
                    });
                    const id = message.get("id") as number;
                    if(id != null){
                        Session.idSet.removeId(id);
                    }
                    SessionManager.getSessionListener().getCurrentFrame()?.forEach(value => {
                        value.onRead(message, this);
                    })
                    const messageChannel = message.get("channel") as string;
                    if(messageChannel == null){
                        return;
                    }

                    const listener = listeners.getByChannel(messageChannel);
                    if(listener === undefined){
                        return;
                    }
                    listener.onRead(message,this);


                }



            }
            socket.onclose = () => {

                this.run = false;
                if(this.killed){
                    return
                }
                // wait 10 seconds
                setTimeout(() => {
                    console.log("reconecting on "+ this.connectData.remoteIP + ":" + this.connectData.port);
                    if(this.tryCount < 50) {
                        this.connect();
                    }
                }, 10*1000)
            }

            //  socket.send("Hello world");
        }
    }

    send(message: Message){
        if(this.currentSocket == null){
            console.log("Websocket is null")
            this.queue.push(message);
            return;
        }
        if(this.currentSocket.OPEN != this.currentSocket.readyState){
            console.log(this.currentSocket.OPEN + " " + this.currentSocket.readyState)
            this.queue.push(message);
            console.log("Not open")
            return;
        }
        this.currentSocket.send(message.toString());
        console.log("Send message : " + message.toString());
    }

    // eslint-disable-next-line
    sendAndWait(message: Message, callback: (response: Message) => void){
        if(this.currentSocket == null){
            return;
        }
        const id = Session.idSet.findAvailableId();
        message.set("id", id);

        this.currentSocket.send(message.toString());
        Session.idSet.addId(id)
        this.currentSocket.onmessage = (event) => {
            if(event.data === null){
                return;
            }
            const message = Message.fromString(event.data);
            const responseID = message.get("id") as number;
            if(responseID == null){
                return;
            }

            if(responseID != id){
                return;
            }
            Session.idSet.removeId(id);
            callback(message);
        }
    }

    kill(){
        this.killed = true;
        console.log("Closing " + this.connectData.remoteIP + ":" + this.connectData.port + " " + this.currentSocket?.readyState)
        this.currentSocket?.close();
    }
}