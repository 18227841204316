
import {useEffect, useImperativeHandle, useState} from "react";
import {animated, useTransition} from "@react-spring/web";




export default function Popup({children , name, isActive, onClose = () => console.log("Closed"), ref} : any) {
  // use context


    

    console.log("UwU " + isActive + "")
    const [toggle, setToggle] = useState(isActive)

    useImperativeHandle(ref, () => ({
        show: () => {
            setToggle(true)
        },
        hide: () => {
            setToggle(false)
        }
    }));
    const spring = useTransition(toggle, {
        from: { opacity: 0, y: 0},
        enter: { opacity: 1, y:30 },
        leave: { opacity: 0, y: 0},

        config: {
            tension: 300,
            friction: 10,
            mass: 1,
            clamp: true,
            velocity: 0,
        }
    })

    useEffect(() => {
        console.log(isActive)
        setToggle(isActive)
    }, [isActive])


    const close = () => {
        setToggle(false)
        onClose()
    }
    return spring((style) => (
        <div>
            {toggle &&
                <div className="z-10 inset-0 overflow-y-auto mx-auto absolute">
            <div className="flex items-end max-sm:min-h-[60%] sm:min-h-[50%] text-center sm:block">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 dark:bg-slate-950 opacity-75"/>
                </div>
                <span className="hidden sm:inline-block sm:align-middle h-screen"
                        aria-hidden="true">&#8203;</span>

                <animated.div style={style}  className="inline-block z-10 bg-white dark:bg-slate-950 mx-auto mt-5 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-lg w-full">
                    <div className="bg-gray-50 dark:bg-slate-800 px-8 flex flex-row">
                        <p className="text-sm text-gray-500 dark:text-white  mt-3 font-medium mr-auto">{name}</p>
                        <button onClick={() =>
                            close()
                        } className="rounded-full flex ml-auto ">
                            <img src={"/svg/Close.svg"} className={"w-6 h-6 my-3 px-1 justify-end rounded-lg hover:bg-danger-100 bg-gray-200 dark:bg-danger-500 dark:hover:bg-danger-600 dark:fill-white"}/>
                        </button>
                    </div>
                    <div className="bg-white dark:bg-slate-950 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        {children}
                    </div>
                </animated.div>

            </div>

        </div>
            }
        </div>
    ))
}