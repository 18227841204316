import "../Dashboard/Pages/Background.css"

export default function Terms() {
    return (
        <div className={"background "}>
        <div className="flex flex-col min-h-screen py-2 sub-background">

            <div className="flex flex-col justify-center items-center w-full flex-1 px-20">
                <h1 className="max-sm:text-2xl sm:text-4xl font-bold underline">
                    Terms of Service
                </h1>
            </div>
            <div className="flex flex-col max-sm:w-11/12 md:w-3/4 flex-1 px-20 my-4 py-4 mx-auto bg-gray-100 rounded-xl drop-shadow-lg overflow-y-auto">
                <p><strong>DreamNetwork Terms of Service</strong></p>

                <p><em>Effective Date: 17/11/2023</em></p>

                <p>Please read these Terms of Service (hereinafter referred to as "Terms") carefully before accessing or using the DreamNetwork website (hereinafter referred to as "the Site") and associated services. By accessing the Site or using our services, you agree to comply with these Terms. If you do not agree to these Terms, please do not use the Site.</p>

                <p><strong>Definitions</strong></p>
                <ul>
                    <li>"DreamNetwork" refers to the platform and services provided through the Site.</li>
                    <li>"User" means any person accessing the Site and using DreamNetwork services.</li>
                    <li>"Hypervisor" refers to the functionality for supervising Minecraft servers and proxies, including the use of Velocity BungeeCord.</li>
                    <li>"Dashboard" denotes the user interface for linking the hypervisor using WebSocket connections.</li>
                </ul>

                <p><strong>Acceptance of Terms</strong></p>
                <p>By accessing the Site and using our services, the User acknowledges having read, understood, and unconditionally accepted these Terms. If the User does not accept these Terms, they must cease using the Site.</p>

                <p><strong>Applicable Laws</strong></p>
                <p>The use of the Site and DreamNetwork services is subject to applicable laws and regulations, including the General Data Protection Regulation (GDPR) and any other relevant data protection laws.</p>

                <p><strong>Data Collection and Usage</strong></p>
                <p>DreamNetwork collects and uses data in accordance with its Privacy Policy, accessible on the Site.</p>

                <p><strong>User Responsibilities</strong></p>
                <p>The User agrees to use DreamNetwork services in a lawful manner and in accordance with these Terms. They are responsible for all activities related to their account.</p>

                <p><strong>Intellectual Property</strong></p>
                <p>All content on the Site, including source code, texts, images, and videos, is the exclusive property of DreamNetwork and is protected by intellectual property laws.</p>

                <p><strong>External Links</strong></p>
                <p>The Site may contain links to third-party websites. DreamNetwork is not responsible for the content or privacy policies of these sites and does not endorse their content.</p>

                <p><strong>Changes to the Terms</strong></p>
                <p>DreamNetwork reserves the right to modify these Terms at any time. Changes will be effective upon posting on the Site.</p>

                <p><strong>Disclaimer</strong></p>
                <p>DreamNetwork provides its services "as is" and does not guarantee the absence of errors or interruptions. In no event shall DreamNetwork be liable for direct or indirect damages arising from the use of the Site.</p>

                <p>By accepting these Terms, the User also acknowledges having read and accepted DreamNetwork's Privacy Policy. For any questions or concerns, please contact us at <a href="https://discord.dreamnetwork.cloud">(https://discord.dreamnetwork.cloud)</a>.</p>

                <p>Thank you for using DreamNetwork!</p>
            </div>
        </div>
        </div>
    )
}