import CenterContent from "../Content/CenterContent";
import Content from "../Content/Content";


interface CenterContentProps {
    setCurrentPage: any
}
export default function NotLinked(props: CenterContentProps) {
    return (
        <>
            <Content>
                <CenterContent isFull={true}>
                    <div className="flex flex-col items-center justify-center select-none">
                        <img src={"/cube.png"} className={"w-32 h-32"}/>
                        <p className={"text-2xl"}>DreamNetwork isn't linked</p>

                        <p className={"text-lg"}>Please link your DreamNetwork account to your hypervisor</p>
                        <button className={"bg-purple-500 hover:bg-purple-400 text-white rounded-lg px-4 py-2 mt-4 "} onClick={() => {
                            props.setCurrentPage(1)
                        }}>Go
                            to Nodes
                        </button>
                    </div>
                </CenterContent>

            </Content>

        </>
    );
}