import React, {useEffect, useState} from "react";
import Navbar from "../Navbar/Navbar";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {TERipple} from "tw-elements-react";
import {toast, ToastContainer} from "react-toastify";
import {GetToken} from "../../RestCommunication/GetToken";


export  interface ISettingsProps {}

// eslint-disable-next-line no-unused-vars
const SettingsPage: React.FunctionComponent<ISettingsProps> = (props) => {
    const { number } = useParams();
    const navigate = useNavigate();

    const project = JSON.parse(localStorage.getItem("projectData-"+number) as string);
    const hypervisor = JSON.parse(localStorage.getItem("hypervisorData-"+number) as string);



    if(project === null || hypervisor === null) {
        return (
            <Navigate to={'/'}/>
        )
    }

    let projectLogo = project.logo
    let projectBackground = project.background


    if(projectLogo === null) {
        projectLogo = "/cube.png"
    }
    let projectBackgroundOpacity = 50
    if(projectBackground !== null) {
        const split = projectBackground.split(" ")
        projectBackground = split[0]
        console.log("Background => "+split[0])
        projectBackgroundOpacity = parseFloat(split[1])*100
        console.log("Opacity => "+projectBackgroundOpacity)
    }
    const [opacityPourcent, setOpacityPourcent] = useState(projectBackgroundOpacity)

    useEffect(() => {
        //YES yup server id number
        if(!number){
            navigate('/')
        }
    },[]);


    return(

        <div className={"min-h-screen sub-background overflow-y-auto"}>
            <ToastContainer/>
            <Navbar isInProject={true} projectName={project.name} projectIcon={project.logo} id={number} selected={1} showProjectIcon={true}/>
            <div
                className={"bg-white dark:bg-black mt-7 sm:w-10/12 lg:w-8/12 h-3/4 my-auto mx-auto border border-dotted drop-shadow-md rounded-lg"}>
                <p className={"text-2xl font-sans ml-4 mt-5"}>Settings ⚙️</p>
                {/* create a line*/}
                <div className={"bg-gray-50 w-full mt-4 p-[1px]"}/>


                <div className={"overflow-y-auto"}>

                    <div className={"flex bg-neutral-100 dark:bg-gray-900 p-4"}>
                        <p className={"font-light pl-8 "}>Project ID</p>
                        <p className={"max-sm:ml-48 md:ml-56"}>N°{project.id}</p>
                    </div>
                    <div className={"flex pb-10 pt-4"}>
                        <p className={"font-light pl-12 absolute"}>Project Name</p>
                        <input id="projectName"
                               className={"hover:border-2 border-gray-300 rounded-lg max-sm:ml-72 md:ml-80 pl-3"}
                               type={"text"}
                               defaultValue={project.name}/>
                    </div>
                    <div className={"flex bg-neutral-100 p-4"}>
                        <p className={"font-light pl-8 resize-none absolute"}>Hypervisor UUID</p>
                        <p className={"max-sm:ml-72 md:ml-80"}>{hypervisor.uuid}</p>
                    </div>
                    <div className={"flex p-4"}>
                        <p className={"pl-8 resize-none absolute font-normal my-2"}>License Key</p>
                        <img src={"/svg/question.svg"} alt={"question mark"}
                             className={"w-4 absolute ml-32 mt-3 cursor-pointer"} onClick={() => {
                            // redirect to the documentation
                            window.open("https://blog.dreamnetwork.cloud/docs/hypervisor/First-Setup/SetupKey", "_blank")
                        }}/>

                        <div className={"ml-72 sm:flex-col md:flex-row"}>
                            <input id="projectName"
                                   className={"border-2 border-gray-300 rounded-lg p-1 mt-1 md:absolute"}
                                   type={"password"} disabled={true} defaultValue={"***********************"}/>
                            <TERipple id={"copyTripple"} className={"transition transition-all ease-in-out delay-50 duration-300 bg-indigo-700 hover:bg-indigo-500 rounded-lg p-2 sm:ml-72 md:ml-64 text-white max-sm:absolute "}>
                                <button id={"buttonCopy"}
                                    onClick={() => {
                                        const n = toast.loading("Loading...")
                                         GetToken(project.id).then((secret) => {
                                           if(secret === undefined){
                                                  toast.update(n, { render: 'Error', type: 'error', isLoading: false, autoClose: 5000 });
                                                  return;
                                           }
                                            toast.update(n, { render: 'Success', type: 'success', isLoading: false, autoClose: 5000 });
                                            copyToClipBoard(secret).then(() => {
                                                console.log("Copied")
                                                const button = document.getElementById("buttonCopy")
                                                if(button === null) return;
                                                button.innerHTML = "Copied 🔑"
                                                // change color to green
                                                const copyTripple = document.getElementById("copyTripple")
                                                if(copyTripple === null) return;
                                                copyTripple.style.backgroundColor = "#04AA6D"
                                                // wait 2 seconds
                                                setTimeout(() => {
                                                    // change text to copy
                                                    button.innerHTML = "Copy 🔑"
                                                    // change color to purple
                                                    copyTripple.style.backgroundColor = "#6366F1"
                                                }, 2000)
                                            })
                                            console.log("Hey hey => "+ secret)

                                            // change text to copied

                                        });
                                    }}>Copy 🔑
                                </button>
                            </TERipple>
                            <TERipple className={"transition ease-in-out delay-50 duration-300 bg-danger-600 hover:bg-danger-500 rounded-lg p-2 text-white sm:ml-10 md:ml-4 max-sm:absolute"}>
                                <button
                                    onClick={() => {
                                        alert("Not implemente️d yet")
                                    }}>Reset
                                </button>
                            </TERipple>
                        </div>
                    </div>
                    <div className={"flex bg-neutral-100 p-4"}>
                        <p className={"font-light pl-8 resize-none absolute"}>IPs Allowed</p>
                        <p className={"max-sm:ml-72 md:ml-80"}>Any</p>
                    </div>
                    <p className={"text-2xl font-sans ml-4 mt-5"}>Teams 👪</p>
                    <div>
                        <div className={"flex bg-neutral-100 p-4"}>
                            <p className={"font-light pl-8 resize-none absolute"}>Teams ID</p>
                            <p className={"max-sm:ml-72 md:ml-80"}>N°{project.team}</p>
                        </div>
                        <div className={"flex max-md:flex-col p-8"}>
                            <p className={"mx-4"}>Users</p>
                            <div className={"bg-neutral-50 flex flex-col px-8 py-3 sm:mr-40 lg:mx-60 rounded-lg drop-shadow-lg"}>
                                <div className={"flex flex-row mt-2"}>
                                   <p>test@gmail.com »</p>

                                    <select className={"ml-3"} >
                                        <option value="owner">Owner</option>
                                        <option value="administrator">Administrator</option>
                                        <option value="moderator">Moderator</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <img src={"/svg/trash.svg"} alt={"trash"} className={"w-4 ml-2 cursor-pointer"}/>

                                </div>
                                <div className={"flex flex-row mt-2"}>
                                    <p>test@gmail.com »</p>

                                    <select className={"ml-3"} >
                                        <option value="owner">Owner</option>
                                        <option value="administrator">Administrator</option>
                                        <option value="moderator">Moderator</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <img src={"/svg/trash.svg"} alt={"trash"} className={"w-4 ml-2 cursor-pointer"}/>

                                </div>
                                <TERipple className={"transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg p-2 m-2 text-white text-center cursor-pointer "} style={
                                    {backgroundColor:"#04AA6D"}
                                }>
                                <button >
                                    Invite new user
                                </button>
                                </TERipple>
                            </div>

                        </div>

                    </div>

                    <p className={"text-2xl font-sans ml-4 mt-5"}>Customization 🍿</p>
                    <div className={"flex bg-neutral-100 p-4"}>
                        <p className={"font-light pl-8 resize-none absolute"}>Project Icon</p>
                        <input id="projectIcon" className={"ml-52 border-2 border-gray-300 rounded-xl p-2 w-2/4"}
                               type={"url"}
                               placeholder={"https://example.com/image.png"}
                               defaultValue={project.logo}/>
                        <img src={projectLogo} alt={"project logo"} className={"h-10 ml-4 rounded-lg"}/>
                    </div>
                    <div className={"flex p-4"}>
                        <p className={"font-light pl-8 resize-none absolute"}>Dashboard Background</p>
                        <input id="projectIcon" className={"ml-52 border-2 border-gray-300 rounded-xl p-2 w-2/4"}
                               type={"url"}
                               placeholder={"https://example.com/image.png"}
                               defaultValue={projectBackground}/>
                        <img src={projectBackground} alt={"project background"} className={"h-10 ml-4 rounded-lg"}/>
                    </div>
                    <div className={"flex bg-neutral-100 p-4"}>
                        <p className={"font-light  pl-8 resize-none absolute"}>Background Transparancy</p>
                        <input id="projectBar" className={"ml-52 border-2 border-gray-300 rounded-xl p-2 w-2/4 accent-purple-500"}
                               type={"range"}
                              defaultValue={opacityPourcent}
                                 min={0}
                                 max={100}
                                    step={1}

                               onChange={event => {
                                      // @ts-ignore
                                   setOpacityPourcent(event.currentTarget.value)
                                     // document.getElementsByName("projectBackground").style.opacity = (100 - parseInt(document.getElementById("projectBar").value)) / 100

                               }}
                        />

                        <p className={"ml-4"}>{
                            // @ts-ignore
                            opacityPourcent
                        }%</p>
                    </div>
                    <div className={"flex  p-4"}>
                        <p className={"font-light pl-8 resize-none absolute"}>Dashboard Theme</p>
                        <select className={"ml-52 rounded-xl bg-white  px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 outline-none"} >
                            <option value="light">Light</option>
                            <option value="dark">Dark</option>
                        </select>
                    </div>
                    <div className={"flex p-4 bg-neutral-100"}>
                        <p className={"font-light pl-8 mt-12 resize-none absolute"}>Project description</p>
                        <textarea id="projectDescription"
                                  className={"ml-52 border-2 border-gray-300 rounded-xl p-2 w-2/4 my-4 py-4 resize-none"}
                                  placeholder={"Your superb project description"}
                                  defaultValue={project.description}/>
                    </div>

                </div>

                <button className={"bg-indigo-700 hover:bg-indigo-500 rounded-lg p-2 text-white flex ml-auto mr-5 my-0"} onClick={() => {
                    alert("Not implemented yet")
                }
                }>Save changes</button>

                <div className={"flex flex-row justify-center mt-4"}/>
            </div>
        </div>
    );
};
function unsecuredCopyToClipboard(text : string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
}

const copyToClipBoard = async (text: string) => {
    if(window.isSecureContext){
        // set timeout in async function
        setTimeout(() => {
            return  navigator.clipboard.writeText(text);
        }, 0)
    }
    else{
       return unsecuredCopyToClipboard(text);
    }

}

export default SettingsPage;