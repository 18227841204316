
import {RequestBuilder} from "./RequestBuilder";


export  const  GetToken = async (id: string | null)  => {
    const request = new RequestBuilder("GET", "/v1/hypervisor/token",true).setBody({
        id: id as string
    });
    let secret;
    await request.sendRequest().then((response) => {
        if(response == null) {
            return;
        }


        response.onSuccess(response => {
            secret = response.data;
        })

    })
    return secret;
}


