import {WidthManager} from "../WidthManager";
import React, { useEffect, useState} from "react";
import {WindowEventListener} from "../../../Utils/WindowEventListener";

export default function Content({children}: any) {
    const [availableWidth,setWidth] = useState(WidthManager.getAvailableWidth(true));



    useEffect(() => {
        WindowEventListener.registerEventListener("resizeContent","resize", () => {
            console.log("resized")
            WidthManager.refreshWidth()
            setWidth(WidthManager.getAvailableWidth(true))
            console.log(availableWidth)
            // react force update
        });
    })


    return (

        <div className="">
            <div className="" style={
                {

                    width: availableWidth + "px",
                    minWidth: availableWidth-10 + "px",
                    maxWidth: availableWidth-10 + "px",
                    display: "inline-block",
                // Styles qui s'appliquent à tous les écrans
                // ...

                // Styles spécifiques aux écrans de petite taille (sm et plus)`


            }}>
                <div className={"flex flex-row"}>
                    {children}
                </div>

            </div>
        </div>
    );
}