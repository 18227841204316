import React, {useEffect, useState} from "react";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {ToastContainer} from "react-toastify";
import Register from "./Register";
import LoginPage from "./Login";


export enum AuthState {
    // eslint-disable-next-line no-unused-vars
    LOGIN,
    // eslint-disable-next-line no-unused-vars
    REGISTER,
    // eslint-disable-next-line no-unused-vars
    FORGOT_PASSWORD,
    //eslint-disable-next-line no-unused-vars
    SUCCESS_REGISTER
}
export interface AuthProps {
    state: AuthState
}
export interface ChildAuthProps {
    state: AuthState
    //eslint-disable-next-line no-unused-vars
    setState: (state: AuthState) => void
    token: string | null
    //eslint-disable-next-line no-unused-vars
    reloadToken: () => void
}
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars

// eslint-disable-next-line no-unused-vars






const ContentAuthPage: React.FunctionComponent<AuthProps> = (props) => {
    const [token, setToken ] = useState(null as string | null);
    useEffect(() => {
        console.log(token);
    }, [token]);

    //eslint-disable-next-line no-unused-vars
    const [refresh, setRefresh] = useState(false);


    // eslint-disable-next-line no-unused-vars
    const [state, setState] = useState(props.state);
    //use transition


    // eslint-disable-next-line no-unused-vars
    const [toggle, setToggle] = useState(true);

    // eslint-disable-next-line no-unused-vars
    const delay = 2000;




    // remove delay
    return  (
            <>
                <GoogleReCaptchaProvider reCaptchaKey="6LdqSOAZAAAAAHQekzDSmVUi2s2KjfPaG-0wmA7Q">
                    <GoogleReCaptcha onVerify={setToken} refreshReCaptcha={refresh} />
                </GoogleReCaptchaProvider>

            <ToastContainer/>

                {state === AuthState.LOGIN &&
                    <LoginPage state={AuthState.LOGIN} token={token} setState={setState} reloadToken={ () => {
                        setToken(null)
                        setRefresh(r => !r)
                    }}/>
                }
                {state === AuthState.REGISTER &&
                    <Register state={AuthState.REGISTER} token={token} setState={setState}  reloadToken={() => {
                        setToken(null)
                        setRefresh(r => !r)
                    }}/>
                }
                {state === AuthState.FORGOT_PASSWORD &&
                    <Register state={AuthState.FORGOT_PASSWORD} token={token} setState={setState}  reloadToken={() =>  {
                        setToken(null)
                        setRefresh(r => !r)
                    }}/>
                }


        </>
    );
}
export default ContentAuthPage;