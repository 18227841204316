import React, {useEffect, useState} from "react";
import SessionManager from "../../../../WebSocket/SessionManager";
import {SessionInterface} from "../../../../WebSocket/SessionListener";
import Message from "../../../../WebSocket/Message";
import Session from "../../../../WebSocket/Session";
import {IServiceProps} from "../Services";
import Ansi from "@cocalc/ansi-to-react";
import {FaArrowLeft, FaStop} from "react-icons/fa6";

export default function ServicePage(props: IServiceProps){
    //eslint-disable-next-line
    const [lines, setLines] = useState([] as string[])
    const [historyRead, setHistoryRead] = useState(true)
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true)
    const [blockScroll, setBlockScroll] = useState(false)
    useEffect(() => {
        const consoleElement = document.getElementById('console');
        if (consoleElement) {
            // si il était en bas avant

            if (historyRead || isScrolledToBottom){
                setBlockScroll(true)
                setTimeout(() => {
                    consoleElement.scrollTo(0, consoleElement.scrollHeight);
                    setBlockScroll(false)
                }, 50);


            }

        }
    }, [lines]);
    useEffect(() => {

        SessionManager.getSessionListener().newFrame("innerService",new class implements SessionInterface {
            onEnter() {
                console.log("entered")

                for (let i = 0; i < SessionManager.getSessions().length; i++) {
                    const session = SessionManager.getSessions()[i];
                    session.send(new Message().set("service", props.service?.name));
                }
            }

            // eslint-disable-next-line no-unused-vars
            onRead(message: Message, session: Session) {
                console.log("Read: "+message.toString())

                if(message.has("instruction")){
                    if(message.get("instruction") === "remove"){
                        props.setService(undefined)
                        return;
                    }
                    if(message.get("instruction") === "noconsole"){
                        setLines(undefined as unknown as string[])
                        return;
                    }

                    if(message.get("instruction") === "console" || message.get("instruction") === "history"){
                        if(!message.has("line")) return;
                        const data = message.get("line")
                        if(data === null || data === undefined) return;
                        let lines: any[];

                        if(data.includes("\n")){
                            lines = data.split("\n")
                        }else {
                            lines = [data]
                        }
                        setLines(prevState => [...prevState, ...lines])
                        if(message.get("instruction") === "history"){
                            setHistoryRead(true)
                            //const consoleElement = document.getElementById('console');
                            //consoleElement?.scrollTo(0, consoleElement.scrollHeight);
                        }else {
                            setHistoryRead(false)
                        }
                        return;
                    }
                }
            }
        })
    }, []);
    return (
        <div className={""}>
            <div className={"flex flex-row"}>
            <div id={"goBack"} className={"hover:cursor-pointer ml-5 mt-6 bg-white rounded-lg  py-1 px-1 w-8 h-8 hover:bg-neutral-100"} onClick={() => {
                props.setService(undefined)
            }
            }>
                <FaArrowLeft className={" mr-2 text-2xl my-5 hover:scale-110 mt-0"} />
            </div>
                <div>
                    <p className={"text-2xl ml-4 mt-5"}>
                        <span className={"hover:rounded-lg hover:bg-neutral-200 hover:cursor-pointer transition-all duration-200"} onClick={() => {
                            props.setService(undefined)
                            props.setSelectedExecutor(undefined)
                        }}>{props.executor?.bundle} </span>
                         /
                        <span className={"hover:rounded-lg hover:bg-neutral-200 hover:cursor-pointer transition-all duration-200"} onClick={() => {
                            props.setService(undefined)
                        }}> {props.executor?.name} </span>
                         /
                        <span className={"rounded-lg bg-neutral-100 hover:cursor-pointer transition-all duration-200"}> {props.service?.name} </span>
                    </p>

                </div>
            </div>

            <div className={"flex  flex-col-reverse"}>


            <div className={"md:ml-10 overflow-hidden rounded-lg"} style={{
                maxWidth: "750px",
                minWidth: "70%",
                padding: "15px 0 15px 0",

            }}>
                <div id={"console"} className={"flex-col flex overflow-y-auto bg-neutral-900 rounded-t-lg  "} onScrollCapture={event => {
                    // check if the user is at the bottom of the console
                    if(blockScroll) return;
                    console.log("scroll")
                    const element = event.currentTarget;
                    const isScrolledToBottom = element.scrollHeight - element.clientHeight <= element.scrollTop + 100;
                    console.log(isScrolledToBottom)
                    setIsScrolledToBottom(isScrolledToBottom)
                }} style={
                    {
                        height: "65vh"
                    }
                }>
                    {

                    }
                    {lines !== undefined && lines.map((value, index) => {
                        return (
                            <Ansi key={index} className="text-md text-neutral-400 font-mono">{value}</Ansi>
                        )
                    })}
                </div>
                <div id={"input"} className="">
                    <input className="w-full h-10 border-2  rounded-b-lg bg-neutral-800 px-1 text-white border-neutral-900 focus:outline-none focus:border-b-purple-400" placeholder={"Type your command"} onKeyDown={(e) => {
                        if(e.key === "Enter"){
                            for (let i = 0; i < SessionManager.getSessions().length; i++) {
                                const session = SessionManager.getSessions()[i];
                                session.send(new Message().set("console",true).set("command", e.currentTarget.value));
                            }
                            e.currentTarget.value = ""
                        }
                    }}/>
                </div>

                <div className={"h-10"}></div>
            </div>
                    <div className={"xl:ml-[8vw] md:ml-[7vw] sm:ml-[2vw] max-sm:ml-[50vw] py-2 bg-white rounded-lg w-[50vw]"}>
                        <button className="mr-2  max-w-[180px] max-h-10 rounded-lg p-1 hover:bg-danger-200 float-right"  onClick={() => {
                            for (let i = 0; i < SessionManager.getSessions().length; i++) {
                                const session = SessionManager.getSessions()[i];
                                session.send(new Message().set("service", props.service?.name).set("instruction", "stop"));
                            }
                        }} ><FaStop className={"inline"}/> Stop</button>
                    </div>








            </div>
        </div>

    )
   /* return (
        <div className="h-full">
            <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                    <p className="text-2xl ml-2">{props.service?.name}</p>
                </div>
                <div className="flex flex-row">
                    <button className="mr-2" onClick={() => {
                        for (let i = 0; i < SessionManager.getSessions().length; i++) {
                            const session = SessionManager.getSessions()[i];
                            session.send(new Message().set("service", props.service?.name).set("instruction", "stop"));
                        }
                    }}>
                        <p className="text-xl text-red-500 hover:text-red-600">Stop</p>
                    </button>
                </div>
            </div>

            <div className="h-full">
                {lines !== undefined && lines.map((value, index) => {
                    return (
                        <p key={index} className="text-lg">{value}</p>
                    )
                })}
            </div>
            <input className="w-full h-10 rounded-xl border-2 border-gray-300 focus:outline-none focus:border-purple-500" onKeyDown={(e) => {
                if(e.key === "Enter"){
                    for (let i = 0; i < SessionManager.getSessions().length; i++) {
                        const session = SessionManager.getSessions()[i];
                        session.send(new Message().set("console",true).set("command", e.currentTarget.value));
                    }
                    e.currentTarget.value = ""
                }
            }}/>
        </div>
)*/

}