import React, {useEffect, useRef, useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import '../Background.css'
import Navbar from "../../Navbar/Navbar";
import LeftBar from "./LeftBar";

import Nodes from "./Nodes";
import Background from "../Background";
import Overview from "./Overview";
import {ToastContainer} from "react-toastify";
import Players from "./Players";
import {Connect, ConnectData} from "../../../RestCommunication/Connect";
import Session from "../../../WebSocket/Session";
import Message from "../../../WebSocket/Message";
import SessionManager from "../../../WebSocket/SessionManager";
import axios from "axios";
import {SessionInterface} from "../../../WebSocket/SessionListener";
import Services, {IServiceProps} from "./Services";
import {WidthManager} from "./WidthManager";


export  interface IDashboardProps {}


export  interface IDashboardProps {}



// eslint-disable-next-line no-unused-vars
const DashboardPage: React.FunctionComponent<IDashboardProps> = (props) => {
    let requests = 0;
    const onSettingsClick = () => {
        setCurrent(0)
        SessionManager.getSessionListener().exitCurrentFrame()
    }
    const { number } = useParams();
    const navigate = useNavigate();
    //const [width,setWidth] = useState(window.innerWidth);
    const [selectedExecutor, setSelectedExecutor] = useState(undefined)
    const [service, setService] = useState(undefined)
    const [findService, setFindService] = useState(undefined)
    const servicesPropsRef = useRef({setSelectedExecutor, setService, findService, setFindService, service, executor: selectedExecutor} as IServiceProps)
    const project = JSON.parse(localStorage.getItem("projectData-"+number) as string);
    const hypervisor = JSON.parse(localStorage.getItem("hypervisorData-"+number) as string);
    const [current, setCurrent] = useState(-1)
    const [connections, setConnections] = useState(Array<ConnectData>())
        //const [ip, setIP] = useState("");
    const getData = async () => {
        console.log("GetData")
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log("get ip address")
        console.log(res.data);
        //setIP(res.data.ip);
        return res.data.ip;
    };



    useEffect(() => {
        SessionManager.getSessions().forEach((session) => {
            const handler = new class implements SessionInterface {
                onRead(message: Message, session: Session) {
                    if (message.get("response") === "services") {
                        setFindService(message.get("services"))
                        session.removeHandler(this)
                    }
                }
            }
            session.addHandler(handler)
            session.send(new Message().set("request", "services").set("find", findService))
        })
    },[findService])



// eslint-disable-next-line no-unused-vars

    if(project === null || hypervisor === null) {
        return (
            <Navigate to={'/'}/>
        )
    }

    useEffect(() => {

        //YES yup server id number
        if(!number){
            navigate('/')
        }
        if(requests > 0) return;
        console.log("Get data")
        getData().then((r) => {
            console.log("Requesting" + requests)
            if(requests > 0) return;
            requests+= 1;

            console.log("Closing "+SessionManager.getSessions().length+" sessions")

            for (let i = 0; i < SessionManager.getSessions().length; i++) {
                const session = SessionManager.getSessions()[i];
                console.log("Closing session " + session.connectData.remoteIP)
                session.kill()
            }
            Connect(number,r,hypervisor.uuid).then(r => {
                if(r === null) return;
                // check if navigator is connected to websockets
                if(Array.isArray(r)){
                    setConnections(r)
                    for (let i = 0; i < r.length; i++) {
                        const connection = r[i];
// eslint-disable-next-line no-unused-vars
                        console.log("New session: "+connection.remoteIP+":"+connection.port)
                        const session = new Session(connection);
                        SessionManager.addSession(session)
                        session.addHandler(new class implements SessionInterface {
                            onRead(message: Message) {
                                console.log("Message: "+message.toString())
                            }
                        })
                        session.connect();
                    //    session.send(new Message().set("type", "getPlayers").set("data", "null"))
                    }
                    setCurrent(0)

                    return;
                }

            })

        })
        window.addEventListener('resize', () => {
          WidthManager.refreshWidth()
        })
    },[]);

    console.log("Number: " + number)


    return (

        //
        <Background  background={project.background}>
            <ToastContainer/>


            {/* Autres éléments de la barre latérale ici */}
             <Navbar isInProject={true} projectName={project.name} projectIcon={project.logo} id={number} selected={0} onSettingsClick={onSettingsClick}/>

            <div className="select-text">
            <div className="flex sm:flex-row max-sm:flex-col overflow-y-hidden overflow-x-hidden min-h-screen min-w-full max-w-full">

                <LeftBar current={current} setCurrent={setCurrent} projectIcon={project.logo} projectName={project.name} projectID={project.id} />
                {current === -1 && <> Connecting </>}
                {current === 0 &&
                     <Overview setCurrentPage={setCurrent} connections={connections}/>
                }
                {current === 1 &&
                    <Nodes ips={hypervisor.nodes as string[]} uuid={hypervisor.uuid} projectId={project.id}/>
                }

                {current === 2 &&
                    <Services  setService={setService} setSelectedExecutor={setSelectedExecutor} executor={selectedExecutor} service={service} findService={findService} setFindService={setFindService}/>
                }
                {current === 3 &&
                    <Players setCurrent={setCurrent} servicesProps={servicesPropsRef.current}/>
                }
            </div>
            </div>

        </Background>
    );
};



// eslint-disable-next-line no-unused-vars

export default DashboardPage;