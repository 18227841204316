import axios from "axios";

import {toast} from "react-toastify";
import {setJWTToken} from "./AxiosAuth";

const notify = () => {
    return toast.loading("Loading...") as number;
}


let toastId: number = 0;
export const getUser = async (mail: string,password: string, token: string) => {
        toastId = notify();
        console.log("new toast id" + toastId)
        console.log("https://devnode.dreamnetwork.cloud:8080/login="+password+"&id=1");
        try {
            axios.defaults.timeout = 10000;
            axios.interceptors.response.use(response => {
                return response;
            }, error => {
                console.log("error here")
                console.log(error.response)
                console.log("Message >>" + error.response.data.message)
                console.log(error.response.status)
                console.log(toastId)
                axios.interceptors.response.clear();
                if (error.response != null && error.response.data.message != null) {

                    toast.update(toastId, {
                        render: 'Auth failed | ' + error.response.data.message,
                        type: toast.TYPE.ERROR,
                        isLoading: false,
                        autoClose: 2000,
                    } );
                    return Promise.resolve();
                }
                toast.update(toastId, {
                        render: 'Auth failed | ' + error,
                        type: toast.TYPE.ERROR,
                        isLoading: false,
                        autoClose: 2000,
                    }
                );
                return Promise.reject(error);
            });

            const response = await axios.post("https://devnode.dreamnetwork.cloud:8080/login", {
                reCaptcha: token,
                email: mail,
                password: password
            }, );

            if(response == null){
                return;
            }
            if(response.status != null && response.status === 401){
                toast.update(toastId, {
                    render: 'Auth failed',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 2000,
                } );
                return;
            }
            // cors
            response.config.headers['Content-Type'] = 'application/json';
            console.log(response.data.message);
            try{
                if(response.data.message === "Success"){
                    localStorage.setItem("jwt", response.data.data);
                    setJWTToken(response.data.data);
                    toast.update(toastId, {
                        render: 'Auth success',
                        type: toast.TYPE.SUCCESS,
                        isLoading: false,
                        autoClose: 2000,
                    } );
                    return response.data
                }
                return;
            }catch (error){

                console.log(error)

                toast.update(toastId, {
                    render: 'Auth failed | ' + error,
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 2000,
                } );

                return;
            }
        }catch (Error){
            console.log(Error);
            toast.update(toastId, {
                render: 'Auth failed | ' + Error,
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 2000,
            });
        }
}