import NotLinked from "./Overview/NotLinked";
import React from "react";
import ViewConnections from "./Overview/ViewConnections";

interface OverviewProps {
    setCurrentPage: any
    connections: any
}
export default function Overview(props: OverviewProps) {
    return (
        <>
            {props.connections.length == 0 &&
                <NotLinked setCurrentPage={props.setCurrentPage}/>
            }
            {props.connections.length > 0 &&
              <ViewConnections connections={props.connections}/>
            }
        </>
    );
}