import axios from "axios";
import {toast} from 'react-toastify';


export const createProject = async (token: string | null, name: string)  => {
    if(token == null){
        localStorage.removeItem("jwt");
        localStorage.removeItem("userData");
        return;
    }
    axios.defaults.timeout = 10000;
    try {
        axios.defaults.timeout = 10000;
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            console.log(error.response)
            console.log("Message >>" + error.response.data.message)
            console.log(error.response.status)
            console.log("Toasting")
            toast.warning("The project could not be created");

            axios.interceptors.response.clear();
            if (error.response != null && error.response.data.message != null) {
                return Promise.resolve();
            }
            return Promise.reject(error);
        });

        const response = await axios.post("https://devnode.dreamnetwork.cloud:8080/v1/project", {
            name: name,
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });



    if(response == null) {
        console.log("Response is null")
        return;
    }

    console.log(response)


    if(response.status != null && response.status === 401){
        console.log("401?")
        toast.error("The project could not be created");
        return;
    }

    if(response.data == null){
        console.log("Data is null")
        toast.error("The project could not be created");
        localStorage.removeItem("jwt");
        localStorage.removeItem("userData");
        return;
    }

    if(response.data.data == null){
        console.log("Data.data is null")
        toast.error("The project could not be created");
        return;
    }

    if(response.data.message === "Unauthorized"){

        console.log("Unauthorized")
        localStorage.removeItem("jwt");
        localStorage.removeItem("userData");
        return;
    }

    if(response.data.message === "User not found"){
        console.log("User not found")
        localStorage.removeItem("jwt");
        localStorage.removeItem("userData");
        return;
    }

    if(response.data.message != "Project created"){
        console.log("Project not created")
        toast.error("The project could not be created");
        return;
    }
    return response;
}catch (e) {
        console.log(e)

    }
}


