import {useEffect, useState} from "react";
import {WidthManager} from "../WidthManager";
import { FaArrowRight} from "react-icons/fa6";
import {WindowEventListener} from "../../../Utils/WindowEventListener";

// eslint-disable-next-line no-unused-vars
export default function RightContent({children}: any) {
    // eslint-disable-next-line no-unused-vars
    const [availableWidth,setWidth] = useState(WidthManager.getRightWidth());
    const [isHidden,setHidden] = useState(false);
    useEffect(() => {
        WindowEventListener.registerEventListener("rightResize",'resize', () => {
            WidthManager.refreshWidth()
            setWidth(WidthManager.getRightWidth())
        });
    });
    return (
        <div className="mr-3" style={
            {

                maxWidth: availableWidth + "px",
                minWidth: availableWidth + "px",
                minHeight: "100%",

            }
        }>
            <div style={
                {
                    maxWidth: availableWidth + "px",
                    minWidth: availableWidth + "px",
                }
            } className="bg-white dark:bg-black absolute sm:ml-4 rounded-xl drop-shadow-lg sm:h-[90%]  md:mt-4 mr-2" >
                <div className={"flex flex-row h-full"}>
                    <div className={"px-1"} style={
                        {

                            minWidth: "2px",
                            maxWidth: "2px",
                        }
                    }>
                        <div id={"arrow"} className={"bg-purple-500 p-4 absolute hover:bg-purple-600 hover:cursor-pointer select-none"} onClick={() => {
                            setHidden(!isHidden)
                            if(isHidden){
                                WidthManager.right = 0
                                WindowEventListener.forceCall("rightResize","resize")
                                WindowEventListener.forceCall("centerResize","resize")
                            }else {
                                WidthManager.right = 250
                                WindowEventListener.forceCall("rightResize","resize")
                                WindowEventListener.forceCall("centerResize","resize")
                            }
                            // rotate arrow 180
                            const arrow = document.getElementById("arrow")
                            if(arrow){
                                if(isHidden) {
                                    arrow.style.transform = "rotate(180deg) translateX(1.7rem)"
                                }else {
                                    arrow.style.transform = "rotate(0deg)"
                                }
                            }
                        }} style={
{
                                // center vertically
                                top: "50%",
                                left: "-0.1rem",
                                transform: "translateY(-50%)",
                                // semi circle
                                borderTopRightRadius: "50%",
                                borderBottomRightRadius: "50%",
                                boxShadow: "0 0 0.3rem rgba(0, 0, 0, 0.5)",
                            }
                        }>

                            <FaArrowRight className={"absolute"} style={
                                {
                                    color: "white",
                                    // center vertically
                                    fontSize: "1.5rem",
                                    top: "50%",
                                    left: "-0.1rem",
                                    transform: "translateY(-50%)",
                                    // semi circle

                                }
                            }/>
                        </div>


                    </div>
                    <div className={"ml-5"}>
                        {children}
                    </div>
                </div>

            </div>
        </div>
    );
}