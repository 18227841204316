import Session from "./Session";
import {SessionListener} from "./SessionListener";

export default class SessionManager {
    static sessions: Session[] = [];
    static sessionListener: SessionListener = new SessionListener();

    static addSession(session: Session){
        SessionManager.sessions.push(session);
    }


    static getSessionListener(): SessionListener{
        return SessionManager.sessionListener;
    }

    static removeSession(session: Session){
        const index = SessionManager.sessions.indexOf(session);
        if(index > -1){
            SessionManager.sessions.splice(index, 1);
        }
    }

    static getSession(id: string): Session | null{
        for(let i = 0; i < SessionManager.sessions.length; i++){
            const session = SessionManager.sessions[i];
            if(session.connectData.id === id){
                return session;
            }
        }
        return null;
    }

    static getSessions(): Session[]{
        return SessionManager.sessions;
    }
}
