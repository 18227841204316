import React, {useState} from 'react';
import {Navigate, Outlet, useNavigate} from 'react-router-dom';
import {getUser} from "../RestCommunication/GetUsername";

const PrivateRoutes = () => {
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    console.log("PrivateRoutes")


    if(localStorage.getItem("jwt") === null ) {
        return <Navigate to={'/auth/login'}/>
    }

    if(!toggle) {
        const user = getUser(localStorage.getItem("jwt"));
        console.log("Page loaded");

        user.then((value) => {
            console.log(value);
            if(value === undefined) {
                localStorage.removeItem("jwt");
                localStorage.removeItem("userData");
                navigate("/auth/login")
                return;
            }
            localStorage.setItem("userData", JSON.stringify(value));
            setToggle(true);
        });
    }



    return (
        // auth ? <Outlet/> : <Navigate to={'/auth/login'}/>
        toggle ? <Outlet/> : <></>
    );
}

export default PrivateRoutes;