import {RequestBuilder} from "./RequestBuilder";

export const Connect = async (id : string | undefined, clientIP : string | undefined, uuid : string) => {
    console.log("Connect to " +clientIP)
    if(id === null || id === undefined){
        return;
    }
    const request = new RequestBuilder("GET", "/v1/hypervisor/connect",true).setBody({
        id: id
    });
    const array = new Array<ConnectData>();
    await request.sendRequest().then((response) => {
        if (response == null) {
            return;
        }
        response.onSuccess(response => {
            if(response.data == null){
                return;
            }
            // check if response.data is an array
            if (!Array.isArray(response.data)) {
                console.log("Response is not an array")
                return;
            }
            for (let i = 0; i < response.data.length; i++) {
                const data = response.data[i];
                const split = data.split(";");
                const secret = split[0];
                const fullIp = split[1];
                const ip = fullIp.split(":")[0];
                const port = fullIp.split(":")[1];
                const connectIP = split[2];
                const connectData = new ConnectData(id, ip, port, secret,connectIP,clientIP,i+1,uuid);
                if (connectData.isSuccess()) {
                    array.push(connectData);
                }
            }
            console.log(array)
            return array;
        })
    })
    return array;
}
export class ConnectData {
    id: string;
    remoteIP: string;
    connectIP: string;
    port: number;
    secret: string;
    nodeNumber : number;
    uuid : string;
    static isDebug = window.location.href.includes("localhost") && false;

    constructor(id: string, remoteIP: string, port: number, secret: string, connectIP: string, clientIP : string | undefined, nodeNumber : number, uuid : string) {
        this.id = id;
        this.remoteIP = remoteIP;
        this.nodeNumber = nodeNumber;
        this.uuid = uuid;
        this.connectIP = connectIP;
        console.log("clientIP : " + clientIP)

        if(this.remoteIP == clientIP || ConnectData.isDebug){
            this.remoteIP = "localhost";
        }
        if(connectIP.includes("%current%") && clientIP != undefined){
            this.connectIP = connectIP.replace("%current%", this.remoteIP)
        }
        this.port = port;
        this.secret = secret;
    }


    isNull() {
        return this.id == null || this.remoteIP == null || this.port == null || this.secret == null;
    }

    isSuccess() {
        return this.id != null && this.remoteIP != null && this.port != null && this.secret != null;
    }
}